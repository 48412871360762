import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/error.css';

import img_error_404 from '../../assets/images/sitio/error_404/error 404_imagen.png';
import volver_img_404 from '../../assets/images/sitio/error_404/boton.png';

const Error404 = () => {
  return(
    <Row>
      <Col span={22} offset={1}>
        <br /><br />
        <img src={img_error_404} width="100%" />
      </Col>
      <Col xs={{span:12,offset:6}} lg={{span:12, offset:6}}>
        <p className="error_text_grende"><strong>LO SENTIMOS</strong></p>
        <p className="error_text">Ésta página no está disponible.<br />
        Es posible que el enlace que seguiste esté roto o se haya eliminado.</p>
        </Col>
        <Col xs={{span:12,offset:6}} lg={{span:6, offset:9}}>
        <Link to="/"><img src={volver_img_404} width="100%" /></Link>
        <br /><br /><br /><br />
      </Col>
    </Row>

  );
}

export default Error404;