import React, { useState, useContext, useEffect, useRef } from "react";
import Registrocompleto from './RegistroCompleto';
import '../../assets/css/login.css';
import {Auth} from '../../utils/AuthContext';
import {Element, scroller} from 'react-scroll';

import banner from '../../assets/images/eventos/volver_al_futuro/BANNER_principalyregistro_scania_volveralfuturo.jpg';
import banner_movil from '../../assets/images/eventos/volver_al_futuro/BANNER_principalyregistro_scania_volveralfuturo.jpg';

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber
} from 'antd';
import  Firebase  from "../../utils/firebaseConfig";
import  { mail_registro_confirmacion } from "../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_crear from '../../assets/images/registro/btn_registro.png';
import img_camion from '../../assets/images/sitio/camion_scania_negro.png';
import wts from '../../assets/images/sitio/registro/problemasconregistro_daclick.png';
import boton_ya_cuenta from '../../assets/images/registro/btn_yacuentoSDF.png';
import boton_ya_cuenta_movil from '../../assets/images/registro/btn_yacuentoSDF_movil.png';

import LoginExiste from '../registro/LoginExiste';

const openNotificationWithIcon = (type,titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 0,
  });
};

const nom_evento = "volver_futuro";

const { Option } = Select;

let correo_registrado = '', password_registrado = '';

const app = new Firebase();

const success = () => {
  openNotificationWithIcon('success','Bienvenido','');
};

const Registro = ({ setsignup, history }) => {
    const [error, seterror] = useState("");
    const [otro_medio, setOtroMedio] = useState(false);
    const [confirmacion, setConfirmacion] = useState(false);
    const [cargando_regsitro, setCargandoRegistro] = useState(false);
    const { usuario, datos } = useContext(Auth);
    const [mostrar_login, setMostrarLogin] = useState(false);
    const [login_econtrado, setLoginEncontrado] = useState(false);
    const [reutilizar_datos, setReutilizarDatos] = useState(false);

    const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_recaptcha = React.createRef();
    

    const elegirMedio = value => {
      console.log(value);

      if(value === 'Otro'){
        setOtroMedio(true);
      }else{
        setOtroMedio(false);
      }

    }

    useEffect(()=>{
      if(usuario){
        app.app.auth().signOut().then(function() {
        
        }).catch(function(error) {
          // An error happened.
        });
        //datos = {};
      }
    },[]);
    
    useEffect(() => {
      if(confirmacion){
        console.log('ir');
        scroller.scrollTo('registro');
      }
        
    },[confirmacion]);

    useEffect(() => {
     
        if(reutilizar_datos){
          
          
        }
    }, [datos, reutilizar_datos]);

    const registrarLoginExiste = (datos)=> {

        correo_registrado = datos.correo;
        datos.scania_connect = 'registrado';
        datos.nombre_curso = nom_evento;
        datos.empresa = datos.procedencia;

        console.log(datos);

        mail_registro_confirmacion(datos).then(({data,status}) => {
          console.log(data);
          switch(status){
            case 0:
              
              //delete values.aceptar_terminos;
              app.escribir(nom_evento+'/usuarios',undefined,datos, () => {
              });
              
              openNotificationWithIcon('success','',data);
              setConfirmacion(true);
            break;
            default:
              openNotificationWithIcon('warning','',data);
              setConfirmacion(false);
              break;
          }
          //setCargandoRegistro(false);
          setMostrarLogin(false);
        });
        setReutilizarDatos(false);


      setReutilizarDatos(true);
    }

    const onFinish = async values => {

      setCargandoRegistro(true);

      let recaptcha = 'sin-recaptcha'; //ref_recaptcha.current.getValue();

        const { prefix, telefono, correo } = values;

        let contra = values.password;

        correo_registrado = correo;

        window.fbq('track', 'Lead');

        values.telefono = values.prefix+''+values.telefono;
        values.scania_connect = 'registrado';

        values.nombre_curso = nom_evento;
              
              if(values.medio_enterado === 'Otro'){
                values.medios = values.otro_medio;
                delete values.otro_medio;
              }else{
                values.medios = values.medio_enterado;
              }

              values.nombre1 = values.nombre;
              values.apellidos = values.ap_paterno+' '+values.ap_materno;
              
              values.procedencia = '';
              values.fecha_nacimiento = '';
              values['g-recaptcha-response'] = recaptcha;
              values.otro_procedencia = values.empresa;
              values.pass = contra;
              values.scania_connect = 'registrado';
              mail_registro_confirmacion(values).then(({data,status}) => {
                switch(status){
                  case 0:
                    console.log(values);
                    delete values.medios;
                    delete values.pass;
                    //delete values.aceptar_terminos;
                    app.escribir(nom_evento+'/usuarios',undefined,values, () => {
                    });
                    app.escribir('usuarios',undefined,values, () => {
                    });
                    
                    openNotificationWithIcon('success','',data);
                    setConfirmacion(true);
                  break;
                  default:
                    openNotificationWithIcon('warning','',data);
                    setConfirmacion(false);
                    break;
                }
                setCargandoRegistro(false);
                
                
              });
      };

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
          
            style={{
              width: '100%',
            }}
          >
            <Option value="+93">+93 Afganistán</Option>
<Option value="+355">+355 Albania</Option>
<Option value="+213">+213 Argelia</Option>
<Option value="+1684">+1 (684) Samoa Americana</Option>
<Option value="+376">+376 Andorra</Option>
<Option value="+244">+244 Angola</Option>
<Option value="+1264">+1 (264) Anguila</Option>
<Option value="+1268">+1 (268) Antigua y Barbuda</Option>
<Option value="+54">+54 Argentina</Option>
<Option value="+374">+374 Armenia</Option>
<Option value="+297">+297 Aruba</Option>
<Option value="+61">+61 Australia</Option>
<Option value="+672">+672 Territorio australiano</Option>
<Option value="+43">+43 Austria</Option>
<Option value="+994">+994 Azerbaiyán</Option>
<Option value="+1242">+1 (242) Bahamas</Option>
<Option value="+973">+973 Bahrain</Option>
<Option value="+880">+880 Bangladesh</Option>
<Option value="+1246">+1 (246) Barbados</Option>
<Option value="+375">+375 Belarus</Option>
<Option value="+32">+32 Bélgica</Option>
<Option value="+501">+501 Belice</Option>
<Option value="+229">+229 Benín</Option>
<Option value="+1441">+1 (441) Bermuda</Option>
<Option value="+975">+975 Bután</Option>
<Option value="+591">+591 Bolivia</Option>
<Option value="+387">+387 Bosnia/Herzegovina</Option>
<Option value="+267">+267 Botsuana</Option>
<Option value="+55">+55 Brasil</Option>
<Option value="+1284">+1 (284) Islas Vírgenes Británicas</Option>
<Option value="+673">+673 Brunéi</Option>
<Option value="+359">+359 Bulgaria</Option>
<Option value="+226">+226 Burkina Faso</Option>
<Option value="+257">+257 Burundi</Option>
<Option value="+855">+855 Camboya</Option>
<Option value="+237">+237 Camerún</Option>
<Option value="+238">+238 Islas de Cabo Verde</Option>
<Option value="+1345">+1 (345) Islas Caimán</Option>
<Option value="+236">+236 República Centroafricana</Option>
<Option value="+235">+235 Chad</Option>
<Option value="+56">+56 Chile</Option>
<Option value="+86">+86 China</Option>
<Option value="+57">+57 Colombia</Option>
<Option value="+269">+269 Comoras</Option>
<Option value="+243">+243 Congo DROC, República Democrática del</Option>
<Option value="+242">+242 Congo ROC, República de</Option>
<Option value="+682">+682 Islas Cook</Option>
<Option value="+506">+506 Costa Rica</Option>
<Option value="+385">+385 Croacia</Option>
<Option value="+53">+53 Cuba</Option>
<Option value="+357">+357 Chipre</Option>
<Option value="+420">+420 República Checa</Option>
<Option value="+45">+45 Dinamarca</Option>
<Option value="+246">+246 Diego García</Option>
<Option value="+253">+253 Yibuti</Option>
<Option value="+1767">+1 (767) Dominica</Option>
<Option value="+1809/829/849">+1 (809/829/849) República Dominicana</Option>
<Option value="+593">+593 Ecuador</Option>
<Option value="+20">+20 Egipto</Option>
<Option value="+503">+503 El Salvador</Option>
<Option value="+240">+240 Guinea Ecuatorial</Option>
<Option value="+291">+291 Eritrea</Option>
<Option value="+372">+372 Estonia</Option>
<Option value="+251">+251 Etiopía</Option>
<Option value="+500">+500 Islas Malvinas</Option>
<Option value="+298">+298 Islas Feroe</Option>
<Option value="+679">+679 Fiyi</Option>
<Option value="+358">+358 Finlandia</Option>
<Option value="+33">+33 Francia</Option>
<Option value="+594">+594 Guyana Francesa</Option>
<Option value="+241">+241 Gabón</Option>
<Option value="+220">+220 Gambia</Option>
<Option value="+995">+995 Georgia</Option>
<Option value="+49">+49 Alemania</Option>
<Option value="+233">+233 Ghana</Option>
<Option value="+350">+350 Gibraltar</Option>
<Option value="+30">+30 Grecia</Option>
<Option value="+299">+299 Groenlandia</Option>
<Option value="+1473">+1 (473) Granada</Option>
<Option value="+590">+590 Guadalupe Antillas Francesas</Option>
<Option value="+502">+502 Guatemala</Option>
<Option value="+224">+224 Guinea</Option>
<Option value="+245">+245 Guinea-Bisáu</Option>
<Option value="+592">+592 Guyana</Option>
<Option value="+509">+509 Haití</Option>
<Option value="+504">+504 Honduras</Option>
<Option value="+852">+852 Hong Kong</Option>
<Option value="+36">+36 Hungría</Option>
<Option value="+354">+354 Islandia</Option>
<Option value="+91">+91 India</Option>
<Option value="+62">+62 Indonesia</Option>
<Option value="+98">+98 Irán</Option>
<Option value="+964">+964 Iraq</Option>
<Option value="+353">+353 Irlanda</Option>
<Option value="+972">+972 Israel</Option>
<Option value="+39">+39 Italia</Option>
<Option value="+225">+225 Costa de Marfil</Option>
<Option value="+1876">+1 (876) Jamaica</Option>
<Option value="+81">+81 Japón</Option>
<Option value="+962">+962 Jordania</Option>
<Option value="+7">+7 Kazajistán</Option>
<Option value="+254">+254 Kenia</Option>
<Option value="+686">+686 Kiribati</Option>
<Option value="+850">+850 Corea Norte</Option>
<Option value="+82">+82 Corea Sur</Option>
<Option value="+965">+965 Kuwait</Option>
<Option value="+996">+996 Kirguizistán</Option>
<Option value="+856">+856 Laos</Option>
<Option value="+371">+371 Letonia</Option>
<Option value="+961">+961 Líbano</Option>
<Option value="+266">+266 Lesoto</Option>
<Option value="+231">+231 Liberia</Option>
<Option value="+218">+218 Libia</Option>
<Option value="+423">+423 Liechtenstein</Option>
<Option value="+370">+370 Lituania</Option>
<Option value="+352">+352 Luxemburgo</Option>
<Option value="+853">+853 Macau</Option>
<Option value="+389">+389 Macedonia</Option>
<Option value="+261">+261 Madagascar</Option>
<Option value="+265">+265 Malaui</Option>
<Option value="+60">+60 Malasia</Option>
<Option value="+960">+960 Maldivas</Option>
<Option value="+223">+223 Malí</Option>
<Option value="+356">+356 Malta</Option>
<Option value="+692">+692 Islas Marshall</Option>
<Option value="+596">+596 Martinica</Option>
<Option value="+222">+222 Mauritania</Option>
<Option value="+230">+230 Islas Mauricio</Option>
<Option value="+52">+52 México</Option>
<Option value="+691">+691 Micronesia</Option>
<Option value="+373">+373 Moldova</Option>
<Option value="+377">+377 Mónaco</Option>
<Option value="+976">+976 Mongolia</Option>
<Option value="+382">+382 Montenegro</Option>
<Option value="+1664">+1 (664) Montserrat</Option>
<Option value="+212">+212 Marruecos</Option>
<Option value="+258">+258 Mozambique</Option>
<Option value="+95">+95 Myanmar Birmania</Option>
<Option value="+264">+264 Namibia</Option>
<Option value="+674">+674 Nauru</Option>
<Option value="+977">+977 Nepal</Option>
<Option value="+31">+31 Holanda</Option>
<Option value="+599">+599 Antillas Neerlandesas Bonaire, Curacao, Saba, St. Eustis</Option>
<Option value="+687">+687 Nueva Caledonia</Option>
<Option value="+64">+64 Nueva Zelanda</Option>
<Option value="+505">+505 Nicaragua</Option>
<Option value="+227">+227 Níger</Option>
<Option value="+234">+234 Nigeria</Option>
<Option value="+1670">+1 (670) Islas Marianas del Norte</Option>
<Option value="+47">+47 Noruega</Option>
<Option value="+968">+968 Omán</Option>
<Option value="+92">+92 Pakistán</Option>
<Option value="+680">+680 Palaos</Option>
<Option value="+970">+970 Autoridad Palestina</Option>
<Option value="+507">+507 Panamá</Option>
<Option value="+675">+675 Papúa Nueva Guinea</Option>
<Option value="+595">+595 Paraguay</Option>
<Option value="+51">+51 Perú</Option>
<Option value="+63">+63 Filipinas</Option>
<Option value="+48">+48 Polonia</Option>
<Option value="+351">+351 Portugal</Option>
<Option value="+974">+974 Qatar</Option>
<Option value="+262">+262 Reunión</Option>
<Option value="+40">+40 Rumania</Option>
<Option value="+7">+7 Rusia</Option>
<Option value="+250">+250 Ruanda</Option>
<Option value="+1670">+1 (670) Saipán Islas Marianas del Norte</Option>
<Option value="+685">+685 Samoa</Option>
<Option value="+378">+378 San Marino</Option>
<Option value="+239">+239 Santo Tomé/Príncipe</Option>
<Option value="+966">+966 Arabia Saudita</Option>
<Option value="+221">+221 Senegal</Option>
<Option value="+381">+381 Serbia</Option>
<Option value="+248">+248 Seychelles</Option>
<Option value="+232">+232 Sierra Leona</Option>
<Option value="+65">+65 Singapur</Option>
<Option value="+1 721">+1 (721) Isla de San Martín</Option>
<Option value="+421">+421 Eslovaquia</Option>
<Option value="+386">+386 Eslovenia</Option>
<Option value="+677">+677 Islas Salomón</Option>
<Option value="+27">+27 Sudáfrica</Option>
<Option value="+211">+211 Sudán Meridional</Option>
<Option value="+34">+34 España</Option>
<Option value="+94">+94 Sri Lanka</Option>
<Option value="+508">+508 San Pedro/Miquelón</Option>
<Option value="+1869">+1 (869) San Cristóbal/Nieves</Option>
<Option value="+1758">+1 (758) Santa Lucía</Option>
<Option value="+1784">+1 (784) San Vicente/Granadinas</Option>
<Option value="+249">+249 Sudán</Option>
<Option value="+597">+597 Suriname</Option>
<Option value="+268">+268 Suazilandia</Option>
<Option value="+46">+46 Suecia</Option>
<Option value="+41">+41 Suiza</Option>
<Option value="+963">+963 Siria</Option>
<Option value="+886">+886 Taiwán</Option>
<Option value="+992">+992 Tayikistán</Option>
<Option value="+255">+255 Tanzania</Option>
<Option value="+66">+66 Tailandia</Option>
<Option value="+228">+228 Togo</Option>
<Option value="+690">+690 Tokelau</Option>
<Option value="+676">+676 Tonga</Option>
<Option value="+1868">+1 (868) Trinidad y Tobago</Option>
<Option value="+216">+216 Túnez</Option>
<Option value="+90">+90 Turquía</Option>
<Option value="+993">+993 Turkmenistán</Option>
<Option value="+1649">+1 (649) Islas Turcas y Caicos</Option>
<Option value="+688">+688 Tuvalu</Option>
<Option value="+256">+256 Uganda</Option>
<Option value="+380">+380 Ucrania</Option>
<Option value="+971">+971 Emiratos Árabes Unidos</Option>
<Option value="+44">+44 Reino Unido</Option>
<Option value="+598">+598 Uruguay</Option>
<Option value="+998">+998 Uzbekistán</Option>
<Option value="+678">+678 Vanuatu</Option>
<Option value="+58">+58 Venezuela</Option>
<Option value="+84">+84 Vietnam</Option>
<Option value="+967">+967 Yemen</Option>
<Option value="+260">+260 Zambia</Option>
<Option value="+263">+263 Zimbabue</Option>
          </Select>
        </Form.Item>
      );

    return (
      <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
      
        <Row>
        <Col xs={0} md={0}>
                <img src={banner} style={{ width:'100%' }} />
            </Col>
            <Col xs={0} md={0}>
                <img src={banner_movil} style={{ width:'100%' }} />
            </Col>
            <Col span={24}>
            <Element name="registro" />
            </Col>
          {confirmacion ? 
          (
            <Col span={24}>
            <Registrocompleto correo={correo_registrado} password={password_registrado} />
            </Col>
          )
        :
        (
          <>
          
<Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:12, offset:6}} lg={{span:18, offset:3}}>

  {/**<br />
  <p className="texto_inicio_registro">
    

  ¿Qué beneficios ofrece el <span className="texto_resaltado_evebto_inicio">uso del gas natural en el
transporte?</span> ¿qué mitos giran en torno a este combustible
fósil? ¿cuál es la perspectiva en materia de infraestructura
para su desarrollo? Más de estas preguntas estaremos
resolviendo sobre este tema alrededor de un recurso natural que aporta
<span className="texto_resaltado_evebto_inicio"> una nueva alternativa al sector del transporte.</span>

<br /><br />
<span className="texto_resaltado_evebto_inicio">¡No te lo puedes perder!</span>

  

        </p>*/}
            <Form className="login-form"
        style={{backgroundColor:'rgb(255,255,255)'}}
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
          prefix: '+52',
        }}
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            <Col span={24}>
            <p className="titulo-registrar">
              <br />
                            <strong>REGÍSTRATE</strong>
                            {!usuario && 
                            (
                              <Row justify="center">
                              <Col xs={{span:0, offset:0}} md={{span:20, offset:0}} lg={{span:16, offset:0}}>
                              <img src ={boton_ya_cuenta} style={{width:'100%', cursor:'pointer'}} onClick={()=> setMostrarLogin(true)} />
                              </Col>
                              <Col xs={{span:20, offset:0}} md={{span:0, offset:0}}>
                              <img src ={boton_ya_cuenta_movil} style={{width:'100%', cursor:'pointer'}} onClick={()=> setMostrarLogin(true)} />
                              </Col>
                              </Row>
                            )
                            }
                            <hr />
                        </p>
            </Col>
           
            <Col span={24}>
            <Form.Item className="nom-label"
        name="nombre"
        label={<span style={{color:'#606060'}}>Nombre(s)</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu nombre</span>,
          },
        ]}
        
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item className="nom-label"
        name="ap_paterno"
        label={<span style={{color:'#606060'}}>Apellido Paterno</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu apellido paterno</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item className="nom-label"
        name="ap_materno"
        label={<span style={{color:'#606060'}}>Apellido Materno</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu apellido materno</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item className="nom-label"
        name="empresa"
        label={<span style={{color:'#606060'}}>Empresa</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el nombre de tu empresa</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>

             <Col span={24}>
            <Form.Item className="nom-label"
        name="correo"
        label={<span style={{color:'#606060'}}>Correo</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa un correo válido</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <p style={{color:'#538ae4', fontWeight:'bold', textAlign:'left', paddingBottom:'0px', marginBottom:'0px'}}><span style={{color:'red', fontWeight:'normal'}}>*</span> Número celular (a 10 dígitos).
            <br />
                <span style={{backgroundColor:'#538ae4', color:'white', textAlign:'left', padding:'5px', marginTop:'0px'}}>Asegúrate de ingresarlo correctamente, ya que te dará acceso el día del evento.</span>
                <br />
                </p>
              </Col>

            <Col xs={{span:24, offset:0}} md={{span:8, offset:0}}>
            {prefixSelector}
              </Col>
                <Col xs={{span:24, offset:0}} md={{span:16, offset:0}}>
            <Form.Item className="nom-label"
        name="telefono"
        label=""
        rules={[
          
          {
            required:true,
            pattern:/^[0-9]{9,12}$/,
            message: <span style={{color:'red'}}>Ingresa un número válido</span>
          },
        ]}
      >
        <Input style={{width:'100%'}} />
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item className="nom-label"
        name="medio"
        label={<span style={{color:'#606060'}}>¿Por qué medio te enteraste del webinar?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Elige un medio</span>,
          },
        ]}
      >
        <Select style={{ width: '100%' }} className="nom-label" onChange={elegirMedio}>
      <Option value="Facebook">Facebook</Option>
      <Option value="Twitter">Twitter</Option>
      <Option value="Instagram">Instagram</Option>
      <Option value="Correo electrónico">Correo electrónico</Option>
      <Option value="Whatsapp">Whatsapp</Option>
      <Option value="Linkedin">Linkedin</Option>
      <Option value="Invitación de Cámara / Asociación">Invitación de Cámara / Asociación</Option>
      
    </Select>
      </Form.Item>
            {otro_medio && 
      (
        <Form.Item className="nom-label"
        name="otro_medio"
        label={<span style={{color:'#606060'}}>¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el medio por el que te enteraste</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
            </Col>
            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item className="nom-label"
        name="aceptar_terminos"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(<span style={{color:'red'}}>Acepta el aviso de privacidad</span>),
          },
        ]}
      >
        <Checkbox className="nom-label">
          Acepto el <span onClick={ () => { window.open('https://www.scania.com/mx/es/home/misc/PrivacyStatement.html','Aviso de privacidad',"top=100,left=500,width=600,height=600");}}>
                      <strong>Aviso de Privacidad</strong>
                    </span> 

          
        </Checkbox>
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item>
              <br />
              <img style={{ width:'200px', padding:'0px', cursor:'pointer' }} src={img_crear} alt="Entrar" onClick={() => { ref_form.current.submit(); }} />
                
                <Button onClick={() => setsignup(false)} type="link" style={{display:'none'}}>
                    Ingresa ahora!
                </Button>
                </Form.Item>
            </Col>
          </Row>

          <div id="recaptcha-container">
          </div>
          
        </Form>
            </Col>
            <Col xs={{span:14, offset:5}} md={{span:8, offset:8}}>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20Scania%20Digital%20Forum."><img src={wts} style={{ width:"100%" }} /></a><br /> <br />
            </Col>
            
            </>
        
        )
        }
            </Row>

        </Spin>

        <Modal
          title="REGISTRO"
          visible={mostrar_login}
          onOk={() => {setMostrarLogin(false)}}
          onCancel={() => {setMostrarLogin(false)}}
          footer={null}
        >
          <LoginExiste onResponse={(response) => {registrarLoginExiste(response) } } onClick={success} />
        </Modal>

        </>
        
    );
};

export default withRouter(Registro);
