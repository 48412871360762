import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';

import rede_facebook from '../../assets/images/registro/footer/icono-fb.png';
import rede_facebook_buses from '../../assets/images/registro/footer/ic-fb_buses.png';
import rede_facebook_truck from '../../assets/images/registro/footer/ic-fb_trucks.png';
import rede_twitter from '../../assets/images/registro/footer/icono-tw.png';
import rede_instagram from '../../assets/images/registro/footer/icono-ig.png';
import rede_ln from '../../assets/images/registro/footer/icono-in.png';
import rede_youtube from '../../assets/images/registro/footer/icono-yt.png';
import scania_bus from '../../assets/images/registro/footer/scania_buses.png';
import scania_trucks from '../../assets/images/registro/footer/scania_trucks.png';

import ima_scania from '../../assets/images/registro/footer/logo_scania_bco.png';

import tira from '../../assets/images/registro/footer/mosaico_footer.jpg';

import camion from '../../assets/images/sitio/home/front.png';
import bus from '../../assets/images/sitio/home/footer_bus.png';
import motor from '../../assets/images/sitio/home/footer_motor.png';


const Footer = () => {

  let location = useLocation();
  const [rede_buses, verRedesBuses] = useState(true);

  return (
    <>
      {location.pathname === '/webinars' || location.pathname === '/conferencias' || location.pathname === '/lanzamientos'
        || location.pathname === '/talks-workshops' || location.pathname === '/ponentes' || location.pathname === '/registro' ?
        <Row>
          <Col span={16} offset={4} style={{ textAlign: 'center' }}>
            <Row>
              <Col span={8}>
                <img src={camion} style={{ width: '100%' }} />
                <br /> <br />
              </Col>
              <Col span={8}>
                <img src={motor} style={{ width: '100%' }} />
                <br /> <br />
              </Col>
              <Col span={8}>
                <img src={bus} style={{ width: '100%' }} />
                <br /> <br />
              </Col>
            </Row>
          </Col>
        </Row>
        :
        <></>
      }
      <Row justify="center" align="top" className="footer" style={{ backgroundColor: '#01356f' }} >
        <Col span={24}>
          <img src={tira} style={{ width: '100%' }} />
        </Col>
        <Col xs={18} md={16} style={{ padding: '3% 3% 0% 3%' }}>
          {!rede_buses ?
            <>
              <Row justify="start" align="top">
                <Col xs={12}>
                  <img style={{ width: '100%' }} src={scania_trucks} />
                </Col>
              </Row>
              <Row justify="start" align="top">

                <Col className="redes" span={4}>
                  <a href="https://www.facebook.com/ScaniaTrucksMx/" target="_blank"><img className="redes" src={rede_facebook} /></a><br /><br />
                </Col>
              </Row>
            </>

            :
            <>
              <Row justify="start" align="middle">
                <Col xs={8} md={4}>
                  <a href="https://www.facebook.com/ScaniaTrucksMx/" target="_blank"><img style={{ width: '100%' }} src={rede_facebook_truck} /></a>
                </Col>
                <Col xs={8} md={4}>
                  <a href="https://www.facebook.com/scaniabusesmx" target="_blank"><img style={{ width: '88%' }} src={rede_facebook_buses} /></a>
                </Col>
                <Col xs={8} md={4}>
                  <a href="https://www.facebook.com/ScaniaMotoresMx/" target="_blank" className="link-footer"><img className='img_face' src={rede_facebook} />MOTORS</a>
                </Col>
                <Col xs={24} md={10}>
                  <Row justify="start" align="middle">
                    <Col span={6}>
                      <a href="https://twitter.com/ScaniaMX" target="_blank"><img className="redes" src={rede_twitter} /></a>
                    </Col>
                    <Col span={6}>
                      <a href="https://www.instagram.com/scania.mx/" target="_blank"><img className="redes" src={rede_instagram} /></a>
                    </Col>
                    <Col span={6}>
                      <a href="https://linkedin.com/company/scaniam%C3%A9xico" target="_blank"><img className="redes" src={rede_ln} /></a>
                    </Col>
                    <Col span={6}>
                      <a href="https://www.youtube.com/channel/UCVBADG61-jHT58GdA8uU2vw" target="_blank"><img className="redes" src={rede_youtube} /></a>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </>
          }
        </Col>
        <Col xs={14} md={6} style={{ padding: '3% 3% 0% 3%' }}>
          <img alt="logo" style={{ width: '70%' }} src={ima_scania} /><br />
        </Col>
        <Col span={24}>
          <Row>
            <Col xs={{ span: 4, offset: 10 }} md={{ span: 3, offset: 2 }}>
              <hr style={{ border: 'none', backgroundColor: '#fe633f', height: '3px' }} />
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={20}>
          <p className="pie-link"><a href="https://www.scaniamexico.com" target="_blank" style={{ color: '#ffffff' }}><strong>www.scaniamexico.com</strong></a></p>
          <p className="pie-peq"> &copy; Copyright Scania 2022 All rights reserved. Scania Comercial S.A. de C.V. Carretera Estatal 431 KM 2+200 Lote 83, Parque Tecnológico Innovación, 76246, Santiago de Querétaro, Querétaro, México, <a href="+524422273000" style={{ color: '#ffffff', textDecoration: 'none' }}>Tel: +52 442 227 30 00</a></p>
        </Col>
        <Col xs={20} md={0}>
          <p className="pie-link" style={{ textAlign: 'center' }}><a href="https://www.scaniamexico.com" target="_blank" style={{ color: '#ffffff' }}><strong>www.scaniamexico.com</strong></a></p>
          <p className="pie-peq" style={{ textAlign: 'center' }}> &copy; Copyright Scania 2022 All rights reserved. Scania Comercial S.A. de C.V. Carretera Estatal 431 KM 2+200 Lote 83, Parque Tecnológico Innovación, 76246, Santiago de Querétaro, Querétaro, México, <br />Tel: <a href="+524422273000" style={{ color: '#ffffff', textDecoration: 'none' }}>+52 442 227 30 00</a></p>
        </Col>

      </Row>
    </>
  );
}

export default Footer;