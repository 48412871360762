import React, { useContext, useState } from 'react';
import { Row, Col, Modal, notification } from 'antd';
import { Link } from 'react-router-dom';
import { Auth } from "../../utils/AuthContext";
import '../../assets/css/ingenieria/evento.css';
import Header from '../master/Header';
import Footer from '../master/Footer';
import CarouselPrincipal from './CarouselPrincipal';
import Login from '../registro/Login';



import vermas from '../../assets/images/sitio/home/leer_mas_ponente.png'

import ponente1 from '../../assets/images/sitio/home/ponente-Hector.png';
import ponente2 from '../../assets/images/sitio/home/ponente-Manuel.png';
import ponente3 from '../../assets/images/sitio/home/ponente-Miguel.png';

import reg from '../../assets/images/sitio/home/btn-registro_ webinars.png';
import revivir from '../../assets/images/sitio/home/btn-revivelo_webinars.png';
import presen from '../../assets/images/sitio/home/btn-presentaciones.png';
import pasado from '../../assets/images/sitio/home/etiqueta_ webinarpasado.png';

import img1 from '../../assets/images/sitio/home/minivistaw-camioneschatos.jpg';
import img2 from '../../assets/images/sitio/home/minivistaw-recomendaciones.jpg';
import img3 from '../../assets/images/sitio/home/minivistaw-mineria.jpg';
import img4 from '../../assets/images/sitio/home/minivistaw-gestioria.jpg';
import img5 from '../../assets/images/sitio/home/minivistaw-invierteencapacitacion.jpg';
import img6 from '../../assets/images/sitio/home/minivistaw-ventajasentuoperacion.jpg';
import img51 from '../../assets/images/sitio/home/minivistaw-flotautobuses.jpg';
import img7 from '../../assets/images/sitio/home/minivistaw-mejoraelrendimiento.jpg';
import img8 from '../../assets/images/sitio/home/minivistaw-comocuidartusautobuses.jpg';
import img9 from '../../assets/images/sitio/home/minivistaw-FOCUS-RESET.jpg';

import ima from '../../../src/assets/images/registro/logo-scaniaforum.png';
import img_mesas from '../../assets/images/sitio/home/btn-mesas_letra.png';
import img_webinar from '../../assets/images/sitio/home/btn-webinars_letra.png';
import img_conf from '../../assets/images/sitio/home/icono_conferencias_y_foros.png';
import img_lanza from '../../assets/images/sitio/home/btn-lanzamiento_letra.png';

import agosto13 from '../../assets/presentaciones/SCANIA_GESTION_EN_EL_TALLER.pdf';
import julio23 from '../../assets/presentaciones/SCANIA_MINERIA_INTELIGENTE.pdf';
import junio11 from '../../assets/presentaciones/SCANIA_VENTAJAS_COMPETITIVAS_DE_UN_CAMION_SCANIA.pdf';
import mayo22 from '../../assets/presentaciones/SCANIA_HABITOS_DE_CONDUCCION_AUTOBUSES.pdf';
import mayo19 from '../../assets/presentaciones/SCANIA_HABITOS_DE_CONDUCCION_CAMIONES_W.pdf';
import abril29 from '../../assets/presentaciones/SCANIA_CUIDADO DE AUTOBUSES EN CONTINGENCIA.pdf';

const progra = [
  {anio:'2020', fecha:'27 AGOSTO', live:'', nombre:'Focus & Re:Set.', descripcion:'', dia:'1', img:img9, url:'https://youtu.be/NpwllW18vdo', link:revivir, presencaiones:'',pas:pasado,descargar:'../../../public/presentaciones', clas:'', mini:'NpwllW18vdo'},
  {anio:'2020', fecha:'26 AGOSTO', live:'', nombre:'Cambiando paradigmas en los camiones Scania.', descripcion:'', dia:'1', img:img1, url:'https://youtu.be/QzqlAIVdjHo', link:revivir, presencaiones:'',pas:pasado,descargar:'../../../public/presentaciones', clas:''},
  {anio:'2020', fecha:'13 AGOSTO', live:'', nombre:'6 recomendaciones para gestionar tu taller.', descripcion:'', dia:'1', img:img2, url:'https://youtu.be/qIgEQZedsyY', link:revivir, presencaiones:presen,pas:pasado,descargar:agosto13, clas:''},
  {anio:'2020', fecha:'23 JULIO', live:'', nombre:'Minería inteligente con soluciones rentables.', descripcion:'', dia:'1', img:img3, url:'https://youtu.be/djaB4rS1Hr0', link:revivir, presencaiones:presen,pas:pasado,descargar:julio23, clas:''},
  {anio:'2020', fecha:'11 JUNIO', live:'', nombre:'Ventajas competitivas de un camión Scania.', descripcion:'', dia:'1', img:img6, url:'https://youtu.be/MRllpPikn1A', link:revivir, presencaiones:presen, pas:pasado,descargar:junio11, clas:''},
  {anio:'2020', fecha:'22 MAYO', live:'', nombre:'Mejora el rendimiento de tu flota de autobuses con mejores hábitos de conducción', descripcion:'', dia:'1', img:img51, url:'https://youtu.be/YdGXtQ_SIhE', link:revivir, presencaiones:presen, pas:pasado,descargar:mayo22, clas:''},
  {anio:'2020', fecha:'19 MAYO', live:'', nombre:'Mejora el rendimiento de tu flota de camiones con mejores hábitos de conducción.', descripcion:'', dia:'1', img:img7, url:'https://youtu.be/JEQXwU5H7vY', link:revivir, presencaiones:presen, pas:pasado,descargar:mayo19, clas:''},
  {anio:'2020', fecha:'29 ABRIL', live:'', nombre:'Cómo cuidar tus autobuses en tiempos de contingencia.', descripcion:'', dia:'1', img:img8, url:'https://youtu.be/Z0k6FVuu5pQ', link:revivir, presencaiones:presen, pas:pasado,descargar:abril29, clas:''}
];


const Home = () =>{

  const { usuario } = useContext(Auth);
  
  let visualizar_programa = progra.map((seccion)=>{
    return(
      <div className="info-horario">
        
        <Row justify="center" align="middle">
          <Col xs={10} md={3}>
            <p style={{ textAlign:'center', marginBottom: '0em' }}>
              <img alt="" style={{ width:'50%' }} src={seccion.live}/><br/>
              <span className="info-fecha">{seccion.fecha}</span></p>
              <p style={{ textAlign:'center' }}>
              <span className="info-anio">{seccion.anio}</span>
              </p>
          </Col>
          <Col md={1}></Col>
          <Col xs={10} md={11} className="sec-titulo">
            <p className="info-completa">
            <span className="info-nombre">{seccion.nombre}</span><br /><br />
            <img className="web-pasado" src={seccion.pas} /></p>
          </Col>
          <Col xs={10} sm={8} md={4}>
            <figure className="containImageLiveANTP">
            <img alt="" style={{ width:'100%', height:'100%' }} className="info-video" src={'https://img.youtube.com/vi/' + seccion.url.substr(17,11) + '/0.jpg'} />
            </figure>
          </Col>
          <Col xs={10} sm={8} md={4} className={seccion.clas}>
            <a href={seccion.url} target="_blank">
              <img  style={{ width:'70%' }} src={seccion.link}/>
            </a><br />
    
            <a href={seccion.descargar} download>
              <img alt="" style={{ width:'70%' }} src={seccion.presencaiones}/>
            </a>
          </Col>
          <Col md={22}>
            <hr style={{ color:'#000000' }} />
          </Col>
        </Row>
      </div>
    );
  });
  
  return(
    <>
    {/*<CarouselPrincipal />*/}

    <Row style={{backgroundColor:'white'}}>
      <Col xs={{span:6, offset:9}} md={{span:4, offset:10}}>
        <br /><br />
        <img alt="logo" style={{width:'100%'}} src={ima}/>
        
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <a href="" target="_blank">
        <img src={img_conf} style={{width:'85%'}} />
        </a>
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <a href="" target="_blank">
        <img src={img_lanza} style={{width:'85%'}} />
        </a>
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <a href="" target="_blank">
          <img src={img_mesas} style={{width:'100%'}} />
        </a>
      </Col>
    </Row>

    <Row style={{ paddingTop:'30px', paddingBottom:'30px', color:'#01356f'}}>
      <Col xs={{span:20, offset:2}} md={{span:18, offset:3}} >

      <p style={{fontSize:'18px', textAlign:'justify'}}>
      Bienvenido a <strong>Scania Digital Forum</strong>, el sitio digital de <strong>Scania México</strong> donde podrás consultar los
webinars que hacemos de interés general sobre nuestra marca, así como aquellos que tendremos
próximamente.<br />
En esta era tan cambiante queremos que nos sientas cerca, y la tecnología es nuestro aliado. <strong>¡Nos da
mucho gusto que nos visites!</strong> Aquí encontrarás contenido enfocado a nuestros camiones, autobuses y
servicios que Scania ofrece, visto desde un enfoque más amigable para ti.
</p>
<p style={{fontSize:'30px', textAlign:'center', textTransform:'uppercase', letterSpacing:'4px'}}><strong>
  ¡Disfruta de la nueva realidad digital!</strong></p>

      </Col>

    </Row>

    <Row justify="center" align="middle" style={{display:'none'}}>

      
      <Col span={24} className="programa" id="programa">
        <Row justify="center" align="middle">
          <Col span={18} align="left">
            <p className="tituPrograma">WEBINARS</p>
          </Col>
          <Col span={24} >
            <Row justify="center" align="middle">
              <Col span={18} className="divPrograma">
                {visualizar_programa}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <br/><br/><br />
          </Col>
          
        </Row>
      </Col>
    </Row>
    </>
  );
}

export default Home;