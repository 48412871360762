import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import CarouselPrincipal from '../evento/CarouselPrincipal';

import vermas from '../../assets/images/sitio/home/leer_mas_ponente.png'

import ponente1 from '../../assets/images/eventos/experiencia_postventa/ROSARio-22.png';
import ponente2 from '../../assets/images/eventos/experiencia_postventa/miguel_guerrero.png';
import ponente3 from '../../assets/images/eventos/experiencia_postventa/antonio_ayala.png';
import ponente4 from '../../assets/images/eventos/gas_natural/P_INICIO/m_miguel.png';

import revivir from '../../assets/images/sitio/home/btn-revivelo_webinars.png';
import presen from '../../assets/images/sitio/home/btn-presentaciones.png';
import pasado from '../../assets/images/sitio/home/etiqueta_ webinarpasado.png';
import conf_pasada from '../../assets/images/sitio/home/etiqueta_conferenciapasada.png';

import img1 from '../../assets/images/sitio/home/minivistaw-camioneschatos.jpg';
import img2 from '../../assets/images/sitio/home/minivistaw-recomendaciones.jpg';
import img3 from '../../assets/images/sitio/home/minivistaw-mineria.jpg';
import img4 from '../../assets/images/sitio/home/minivistaw-gestioria.jpg';
import img5 from '../../assets/images/sitio/home/minivistaw-invierteencapacitacion.jpg';
import img6 from '../../assets/images/sitio/home/minivistaw-ventajasentuoperacion.jpg';
import img51 from '../../assets/images/sitio/home/minivistaw-flotautobuses.jpg';
import img7 from '../../assets/images/sitio/home/minivistaw-mejoraelrendimiento.jpg';
import img8 from '../../assets/images/sitio/home/minivistaw-comocuidartusautobuses.jpg';
import img9 from '../../assets/images/sitio/home/minivistaw-FOCUS-RESET.jpg';
import mini_comnect from '../../assets/images/sitio/home/Minivista_conectividad.png';
import mini_euro_6 from '../../assets/images/sitio/home/minivista_euro6_scania_.jpg';
import mini_convierte_costos from '../../assets/images/sitio/home/minivista-22oct.jpg';
import mini_gas_natural from '../../assets/images/sitio/home/minivista_gasnatural.jpg';

import nov13 from '../../assets/presentaciones/Scania_13_NOV.pdf';
import oct22 from '../../assets/presentaciones/scania_convierte_tus_costos_fijos_en_variables_presentacion.pdf';
import oct9 from '../../assets/presentaciones/Presentación Webinar Motores Euro 6_10_FINAL.pdf';
import sep23 from '../../assets/presentaciones/Conectvidad_la_clave_para_un_negocio_rentable_version.pdf';
import agosto26 from '../../assets/presentaciones/cambiando_paradigmas_en_los camiones_scania.pdf';
import agosto13 from '../../assets/presentaciones/SCANIA_GESTION_EN_EL_TALLER.pdf';
import julio23 from '../../assets/presentaciones/SCANIA_MINERIA_INTELIGENTE.pdf';
import junio25 from '../../assets/presentaciones/Presentacion_webinar_Driver_Services.VF.pdf';
import junio11 from '../../assets/presentaciones/SCANIA_VENTAJAS_COMPETITIVAS_DE_UN_CAMION_SCANIA.pdf';
import mayo22 from '../../assets/presentaciones/SCANIA_HABITOS_DE_CONDUCCION_AUTOBUSES.pdf';
import mayo19 from '../../assets/presentaciones/SCANIA_HABITOS_DE_CONDUCCION_CAMIONES_W.pdf';
import abril29 from '../../assets/presentaciones/SCANIA_CUIDADO DE AUTOBUSES EN CONTINGENCIA.pdf';
import febrero25 from '../../assets/presentaciones/Webinar_Innovacion_febrero_final_n_.pdf';
import marzo31 from '../../assets/presentaciones/Propuesta_de_valor_PPT_31MARZO2021.pdf';
import julio14 from '../../assets/presentaciones/Quienes_son_los_grandes_ganadores_del_transporte_en_Mexico.pdf';
import agosto10_21 from '../../assets/presentaciones/Webinar_El_valor_de_la_postventa_es_la_venta_final.pdf';
import agosto26_21 from '../../assets/presentaciones/postventa_scania_camiones.pdf';
import octubre27_21 from '../../assets/presentaciones/Webinar_Autobuses_de_personal_final.pdf';


import '../../assets/css/conferencia.css';

const webinars = [
  {anio:'2023', fecha:'29 MARZO', live:'', nombre:'Scania Fleet Managment ahora es My Scania', descripcion:'', dia:'1', img:'', url:'https://youtu.be/tHnwGPQWJd4', link:revivir, presencaiones:'', pas:pasado, descargar:'', clas:''  },
  {anio:'2021', fecha:'27 OCTUBRE', live:'', nombre:'Transporte de personal y minería', descripcion:'', dia:'1', img:'', url:'https://youtu.be/a32Kd6bxoJ4', link:revivir, presencaiones:presen, pas:pasado, descargar:octubre27_21, clas:''  },
  {anio:'2021', fecha:'26 AGOSTO', live:'', nombre:'Experiencia postventa scania para camiones', descripcion:'', dia:'1', img:'', url:'https://youtu.be/E_1L5YTHMWg', link:revivir, presencaiones:presen, pas:pasado, descargar:agosto26_21, clas:''  },
  {anio:'2021', fecha:'10 AGOSTO', live:'', nombre:'El verdadero valor de la venta es la postventa', descripcion:'', dia:'1', img:'', url:'https://youtu.be/KZRaTiJBvF4', link:revivir, presencaiones:presen, pas:pasado, descargar:agosto10_21, clas:''  },
  {anio:'2021', fecha:'14 JULIO', live:'', nombre:'¿Quiénes son los grandes ganadores del transporte en México?', descripcion:'', dia:'1', img:'', url:'https://youtu.be/r-jch-D1WgM', link:revivir, presencaiones:presen, pas:pasado, descargar:julio14, clas:''  },
  {anio:'2021', fecha:'31 MARZO', live:'', nombre:'Propuesta de valor para operación urbana', descripcion:'', dia:'1', img:'', url:'https://youtu.be/hKux2Vk59wE', link:revivir, presencaiones:presen, pas:pasado, descargar:marzo31, clas:''  },
  {anio:'2021', fecha:'25 FEBRERO', live:'', nombre:'¿Mayor rentabilidad en tiempos de crisis?', descripcion:'', dia:'1', img:'', url:'https://youtu.be/BrdXH7nAJTw', link:revivir, presencaiones:presen, pas:pasado, descargar:febrero25, clas:''  },
  {anio:'2020', fecha:'13 NOVIEMBRE', live:'', nombre:'Gas natural, un aliado para el transporte sustentable.', descripcion:'', dia:'1', img:mini_gas_natural, url:'https://youtu.be/EZuBI9ndd_M', link:revivir, presencaiones:presen, pas:pasado, descargar:nov13, clas:''  },
  {anio:'2020', fecha:'22 OCTUBRE', live:'', nombre:'Convierte tus costos fijos en variables y escala tu negocio a un nuevo nivel.', descripcion:'', dia:'1', img:mini_convierte_costos, url:'https://youtu.be/YQzEMN_ainY', link:revivir, presencaiones:presen, pas:pasado, descargar:oct22, clas:''  },
  {anio:'2020', fecha:'8 OCTUBRE', live:'', nombre:'Motores Euro 6, óptima operación y cuidado del medio ambiente.', descripcion:'', dia:'1', img:mini_euro_6, url:'https://youtu.be/Nz-RvaUYKSM', link:revivir, presencaiones:presen, pas:pasado, descargar:oct9, clas:''  },
    {anio:'2020', fecha:'23 SEPTIEMBRE', live:'', nombre:'Conectividad, la clave rentable para tu negocio.', descripcion:'', dia:'1', img:mini_comnect, url:'https://youtu.be/4nrNNzmVbRk', link:revivir, presencaiones:presen, pas:pasado, descargar:sep23, clas:''  },
    {anio:'2020', fecha:'26 AGOSTO', live:'', nombre:'Cambiando paradigmas en los camiones Scania.', descripcion:'', dia:'1', img:img1, url:'https://youtu.be/QzqlAIVdjHo', link:revivir, presencaiones:presen,pas:pasado,descargar:agosto26, clas:''},
    {anio:'2020', fecha:'13 AGOSTO', live:'', nombre:'6 recomendaciones para gestionar tu taller.', descripcion:'', dia:'1', img:img2, url:'https://youtu.be/qIgEQZedsyY', link:revivir, presencaiones:presen,pas:pasado,descargar:agosto13, clas:''},
    {anio:'2020', fecha:'23 JULIO', live:'', nombre:'Minería inteligente con soluciones rentables.', descripcion:'', dia:'1', img:img3, url:'https://youtu.be/djaB4rS1Hr0', link:revivir, presencaiones:presen,pas:pasado,descargar:julio23, clas:''},
    {anio:'2020', fecha:'25 JUNIO', live:'', nombre:'Invierte en capacitación, ahorra en operación.', descripcion:'', dia:'1', img:img6, url:'https://youtu.be/EGMfvBcmKAE', link:revivir, presencaiones:presen, pas:pasado,descargar:junio25, clas:''},
    {anio:'2020', fecha:'11 JUNIO', live:'', nombre:'Ventajas competitivas de un camión Scania.', descripcion:'', dia:'1', img:img6, url:'https://youtu.be/MRllpPikn1A', link:revivir, presencaiones:presen, pas:pasado,descargar:junio11, clas:''},
    {anio:'2020', fecha:'22 MAYO', live:'', nombre:'Mejora el rendimiento de tu flota de autobuses con mejores hábitos de conducción.', descripcion:'', dia:'1', img:img51, url:'https://youtu.be/YdGXtQ_SIhE', link:revivir, presencaiones:presen, pas:pasado,descargar:mayo22, clas:''},
    {anio:'2020', fecha:'19 MAYO', live:'', nombre:'Mejora el rendimiento de tu flota de camiones con mejores hábitos de conducción.', descripcion:'', dia:'1', img:img7, url:'https://youtu.be/JEQXwU5H7vY', link:revivir, presencaiones:presen, pas:pasado,descargar:mayo19, clas:''},
    {anio:'2020', fecha:'29 ABRIL', live:'', nombre:'Cómo cuidar tus autobuses en tiempos de contingencia.', descripcion:'', dia:'1', img:img8, url:'https://youtu.be/Z0k6FVuu5pQ', link:revivir, presencaiones:presen, pas:pasado,descargar:abril29, clas:''}
  ];


const Webinars = () => {


    let visualizar_webinars = webinars.map((conferencia)=>{
        return(
          <div className="info-horario_conf">
            
            <Row align="middle">
              <Col xs={{span:20, offset:2}} md={{span:3, offset:0}}>
                <p style={{ textAlign:'center', marginBottom: '0em' }}>
                  <img alt="" style={{ width:'50%' }} src={conferencia.live}/><br/>
                  <span className="info-fecha_conf">{conferencia.fecha}</span></p>
                  <p style={{ textAlign:'center' }}>
                  <span className="info-anio_conf">{conferencia.anio}</span>
                  </p>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:1}} className="sec-titulo_conf">
                <p className="info-completa_conf">
                <span className="info-nombre_conf">{conferencia.nombre}</span><br />
                <img className="web-pasado_conf" src={conferencia.pas} />
                <br />
                </p>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:4, offset:0}}>
              <a href={conferencia.url} target="_blank">
                <img src={"https://img.youtube.com/vi/"+conferencia.url.substring(17,28)+"/mqdefault.jpg"} className="info-video_conf" style={{ width:'100%' }}/>
                </a>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:4, offset:0}} className={conferencia.clas}>
                <a href={conferencia.url} target="_blank">
                  <img className="btn_agenda" src={conferencia.link}/>
                </a><br />
        
                <a href={conferencia.descargar} download>
                  <img className="btn_agenda" alt="" src={conferencia.presencaiones}/>
                </a>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:22, offset:2}}>
                <hr style={{ color:'#000000' }} />
              </Col>
            </Row>
          </div>
        );
      });


    return(
      <>
      <Row className="contenedor_conferencias">
        
      <Col span={24}>
          <br />
          <p className="palabra_inicio_seccion">WEBINARS</p>
        </Col>
        <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:14, offset:4}} lg={{span:16, offset:4}} style={{textAlign:'center', display:'non'}}>
          <div className="contenedor_video_conferencia">
          <iframe src="https://www.youtube.com/embed/tHnwGPQWJd4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Col>

        <Col span={0} style={{backgroundColor:'white', display:'none'}} >
        <CarouselPrincipal />
      </Col>
      <Col span={24}>
        
        <Row justify="center" align="middle">
          

          <Col xs={24} md={24} style={{display:'none'}}>
            <p className="titulo-ponentes">PONENTES</p>
          </Col>
          <Col span={20}>
          <Row justify="center" style={{display:'none'}}>
          <Col xs={18} md={6}>
            <img src={ponente3} style={{ width:'100%'}} /><br />
            <Link to="/ponentes#antonio_ayala"><img src={vermas} style={{ width:'130px' }} /></Link>
            <br /><br />
          </Col>
          <Col xs={18} md={6}>
            <img src={ponente2} style={{ width:'100%'}} /><br />
            <Link to="/ponentes#miguel_guerrero"><img src={vermas} style={{ width:'130px' }} /></Link>
            <br /><br />
          </Col>
          <Col xs={18} md={6}>
            <img src={ponente1} style={{ width:'100%'}} /><br />
            <Link to="/ponentes#rosario_ayala"><img src={vermas} style={{ width:'130px' }} /></Link>
            <br /><br />
          </Col>
          
          </Row>
          </Col>
          <Col span={24}>
            <br />
          </Col>
        </Row>
      </Col>
        <Col span={24}>
          <br /><br />
        </Col>
        {/*<Col span={24}>
          
          <p className="palabra_inicio_seccion">WEBINARS</p>
        </Col>
        <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:14, offset:4}} lg={{span:16, offset:4}} style={{textAlign:'center'}}>
          <div className="contenedor_video_conferencia">
          <iframe src="https://www.youtube.com/embed/hKux2Vk59wE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Col>
        <Col span={24}>
          <br /><br /><br />
    </Col>*/}
        <Col span={20} offset={2} className="contenedor_programas">
        {visualizar_webinars}
        </Col>
        <Col span={24}>
          <br /><br /><br />
        </Col>
      </Row>
        
        </>
    );
}

export default Webinars;
