import React, {useState, useEffect} from 'react';

import {Switch, Route, useLocation} from 'react-router-dom';
import {Element, scroller} from 'react-scroll';

import Login from '../registro/Login';
import Error404 from "../error/Error404";
import Home from '../evento/Home';
import Ponentes from '../evento/Ponentes';
import Registro from '../registro/Registro';
import RegistroCompleto from '../registro/RegistroCompleto';
import Conferencias from '../conferencias/Conferencias';
import Webinars from '../conferencias/Webinars';


import Demo from '../en-vivo/Demo';

import { AuthContext } from '../../utils/AuthContext';
import Admin from '../admin/Admin';
import VisualizarPreguntas from '../admin/VisualizarPreguntas';
import CerrarSesion from '../sesion/CerrarSesion';
import Header from './Header';
import Footer from './Footer';
import RegistroLiderazgo from '../registro/registros/RegistroLiderazgo';
import PropuestaValor from '../registro/registros/PropuestaValor';
import Lanzamientos from '../conferencias/Lanzamientos';
import Encuesta from '../conferencias/Encuesta';
import Talks from '../conferencias/Talks';



export const Master = () => {

  const [boton_mostrar_login, setBotonMostrarLogin] = useState(true);
  const location = useLocation();

  //useEffect(()=>{console.log(boton_mostrar_login);},[boton_mostrar_login]);

  const secciones_auth = [
    {componente:Home, url:'/inicio'},
    //{componente:Home, url:'/'},
    {componente:<Home onResponseMostrarLogin={setBotonMostrarLogin} />, url:'/'},
    //{componente:PropuestaValor, url:'/registro'},
    //{componente:RegistroLiderazgo, url:'/registro/foro-de-liderazgo'},
    {componente:Registro, url:'/registro'},
    //{componente:Registro, url:'/registro/grandes-ganadores-del-transporte-en-mexico'},
    //{componente:Ponentes, url:'/ponentes'},
    {componente:<Demo onResponseMostrarLogin={setBotonMostrarLogin} />, url:'/en-vivo'},
    {componente:RegistroCompleto, url:'/completo'},
    {componente:Login, url:'/login'},
    {componente:Conferencias, url:'/conferencias'},
    {componente:Encuesta, url:'/encuesta'},
    {componente:Webinars, url:'/webinars'},
    {componente:Talks, url:'/talks-workshops'},
    {componente:Lanzamientos, url:'/lanzamientos'},
    {componente:Error404, utl:'/*'}
  ];
  
  const secciones = [
    {componente:Admin, url:'/admin-preguntas-live'},
    {componente:VisualizarPreguntas, url:'/visualizar-preguntas'},
    {componente:CerrarSesion, url:'/cerrar-sesion'},
    //{componente:Lanzamientos, url:'/'},
  ];

  

    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{

      if(seccion.url === '/en-vivo' || seccion.url === '/'){
        return(
          <Route path={seccion.url} exact>
            {seccion.componente}
            </Route>
        );
      }

      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

    useEffect(() => {
      if(location.pathname === '/registro'){
        setBotonMostrarLogin(false);
      }
    },[]);

    useEffect(() => {
      console.log('location',location);
      if(location.hash === ''){
          scroller.scrollTo('header');
      }else{
          console.log('ir');
          console.log(location);
          scroller.scrollTo(location.hash);
      }
      
  });
  
  return(
    
    <AuthContext >
      <Element name="header" />
      <Header mostrar_boton_login={boton_mostrar_login} onResponseMostrarLogin={setBotonMostrarLogin} />
      <Switch>
      {visualizar_secciones}
        {visualizar_secciones_auth}
        
      </Switch>
      <Footer />
    </AuthContext>

  );
}