import React, { useEffect, useContext ,useState} from "react";
import { Row, Col, Modal, Spin, message , Button, notification} from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';
import {Preguntas} from '../conferencias/Preguntas'; 
import {Estadisticas} from '../conferencias/Estadisticas';

import Login from '../registro/Login';

import Header from '../master/Header';
import Footer from '../master/Footer';

const nombre_evento = 'volver_futuro';

const app = new Firebase();

let key = '';
const success = () => {
    mensaje('success','Bienvenido','');
  };

  const mensaje = (tipo,titulo, texto) =>{

    notification[tipo]({
      message: titulo,
      description: texto,
    });
  
  }

const Demo =({history, onResponseMostrarLogin}) =>{

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [error_correo, setErrorCorreo] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [respuestas, setRespuestas] = useState([]);
    const [dia, setDia] = useState(1);
    const [ver_video,setVerVideo] = useState(1);
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_login, setMostrarLogin] = useState(false);


    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                    app.obtener_objetos(nombre_evento+'/preguntas', false, (datos) => {
                        setPreguntas(datos);
                        app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                            setEstadisticas(datos);
                            setCargando(false);
                            if (typeof existe_respuestas !== 'undefined') {
                                setEtapa(2);
                            } else {
                                setEtapa(1);
                            }
                        });
                    })
                
                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
            break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        const actualizar_usuario = {
            telefono: usuario.phoneNumber,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + '/usuarios-encuesta', key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {
        
        if (!usuario) {
            //mensaje('warning','Sesión inactiva','Necesitas estar logueado para acceder a esta transmisión');
            //window.location.href = "/";
        }else{

            app.obtener_objetos(nombre_evento+`/configuracion`,false, (datos) => {
                setDia(datos[0].dia);
                setVerVideo(datos[0].ver_video);
                if(Object.entries(datos).length !== 0){
                    app.obtener_objeto(nombre_evento+`/usuarios-encuesta${datos[0].dia}`, 'telefono', usuario.phoneNumber, siguienteEtapa);
                }
                app.obtener_objetos(nombre_evento+`/preguntas-publico${datos[0].dia}`,true, (data) => {
                    setPreguntasPonente(data);
                });
                app.obtener_objetos(nombre_evento+`/respuestas-admin${datos[0].dia}`,true, (data) => {
                    setRespuestas(data);
                });
            });
            
            
        }
        
    }, [history, usuario, datos]);

    return (
        <>
        <div className="cuerpo">
            <Row justify="center" align="">
            <Col span={24}>
                <Live 
                url={"https://www.youtube.com/embed/A8Y6SBND1Jo?rel=0;autoplay=1"}
                urlyt={null}
                urlfb={null} 
                urlln={null}
                webinar={nombre_evento}
                comentarios={preguntas_ponente} 
                onResponseMostrarLogin = {onResponseMostrarLogin}
                dia={dia}
                ver_video={ver_video}
                respuestas={respuestas}
                />
            {/* ?rel=0;autoplay=1 */}
            </Col>
            <Col span={24}>
                <br/><br />
            </Col>

<Col span={24}  style={{display:'none'}}>
            <Spin tip="Cargando..." spinning={cargando} >
            {
                    etapa === 1 ?
                        (
                            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} >
                                {<Preguntas preguntas={preguntas} onResponse={guardarRespuestas} />}
                            </Col>
                        )
                        :
                        (
                            <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }}>
                                Gracias
                                {<Estadisticas estadisticas={estadisticas} preguntas={preguntas} style={{display:'none'}} />}
                            </Col>
                        )
                }
                </Spin>
                <br/><br/>
                </Col>
            
            </Row>
                <Row justify="center" align="middle" style={{backgroundColor:'#ededed'}}>
                    <Col xs={{span:0}} lg={{span:24}}>
                    
                    </Col>

                    <Col xs={{span:24}} lg={{span:0}} style={{display:'none'}}>
                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    </Col>
                    <Col span={16} offset={4} style={{textAlign:'center'}}>
                
        </Col>
    </Row>
        </div>

        

                </>
    );

}

export default Demo;