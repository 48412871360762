import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Form, Input, Button, Modal, Select, Spin, message, notification } from "antd";
import { Link } from 'react-router-dom';
import  Firebase  from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import Errores from './Errores'
import '../../assets/css/registro.css';
import { permitir_entrada_webinar, aceptar_codigo } from '../../utils/ConexionServidor';

import img_crear from '../../assets/images/sitio/btn_registrate.png';
import img_entrar from '../../assets/images/sitio/btn_registrate.png';

const app = new Firebase();
const nombre_webinar = 'volver_futuro';

let function_recaptcha;

const { Option } = Select;

let recaptcha_phone;

let confirmacionCodigo;

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const Login = ({ history, onResponse, onResponseLogin }) => {

    const { usuario } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [ingresar_codigo, setIngresarCodigo] = useState(false);
    const [cargando_login, setCargandoLogin] = useState(false);

    const [form] = Form.useForm();
    
    const ref_codigo = useRef(null);
    const ref_form_login = useRef(null);

    useEffect(() => {

        if (usuario) {
            //alert('usuario registrado');
        }
    }, [history, usuario]);

    const onFinish = async values => {

        setCargandoLogin(true);

        const { prefix, telefono } = values;
        values.nombre_curso = nombre_webinar;
        values.telefono = prefix+telefono

        permitir_entrada_webinar(values).then(({data, status}) => {
          switch(status){
            case 0:
              localStorage.setItem('usuario',JSON.stringify(data));
              setIngresarCodigo(true);
              setCargandoLogin(false);
              /*app.app.auth().signInWithPhoneNumber(values.telefono, recaptcha_phone)
              .then(function (confirmationResult) {
                // confirmationResult can resolve with the whitelisted testVerificationCode above.
                //return confirmationResult.confirm(testVerificationCode)
                function_recaptcha = confirmationResult;
                
              }).catch(function (error) {
                // Error; SMS not sent
                // ...
                mensaje('error','Este numero no ha sido registrado, porfavor regístrate antes de iniciar sesión');
                
                setIngresarCodigo(false);
                setCargandoLogin(false);
              });*/

            break;
            default:
              mensaje('error',data);
                
                setIngresarCodigo(false);
                setCargandoLogin(false);
              break;
          }
        });


        console.log(prefix+telefono);

        



    };


    useEffect(() => {
        
        recaptcha_phone = window.recaptchaVerifier = new app.app.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //ref_form_login.current.submit();
            }
          });;
    },[]);

    const colocarCodigo = () => {
        setCargandoLogin(true);
        console.log(ref_codigo.current.state.value);

        console.log(JSON.parse(localStorage.getItem('usuario')));
        aceptar_codigo(nombre_webinar,JSON.parse(localStorage.getItem('usuario')).telefono,ref_codigo.current.state.value).then(({status, data}) => {

          switch(status){
            case 0:
              mensaje('success','Bienvenido','');
              localStorage.setItem('usuario',JSON.stringify(data));
              app.app.auth().signInAnonymously()
              .then(() => {
                onResponseLogin();
                setCargandoLogin(false);
              })
              .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
              });
            break;
            default:
              mensaje('error','Código incorrecto');
              setCargandoLogin(false);
            break;
          }
          

        });

        /*function_recaptcha.confirm(ref_codigo.current.state.value).then(function (result) {
            // User signed in successfully.
            var user = result.user;
            onResponseLogin();
            setCargandoLogin(false);
            // ...
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
            mensaje('error','Código incorrecto');
            setCargandoLogin(false);
          });
          */

      }

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: '100%',
            }}
            className="select_telefono"
            
          >
          <Option value="+93">+93 Afganistán</Option>
<Option value="+355">+355 Albania</Option>
<Option value="+213">+213 Argelia</Option>
<Option value="+1684">+1 (684) Samoa Americana</Option>
<Option value="+376">+376 Andorra</Option>
<Option value="+244">+244 Angola</Option>
<Option value="+1264">+1 (264) Anguila</Option>
<Option value="+1268">+1 (268) Antigua y Barbuda</Option>
<Option value="+54">+54 Argentina</Option>
<Option value="+374">+374 Armenia</Option>
<Option value="+297">+297 Aruba</Option>
<Option value="+61">+61 Australia</Option>
<Option value="+672">+672 Territorio australiano</Option>
<Option value="+43">+43 Austria</Option>
<Option value="+994">+994 Azerbaiyán</Option>
<Option value="+1242">+1 (242) Bahamas</Option>
<Option value="+973">+973 Bahrain</Option>
<Option value="+880">+880 Bangladesh</Option>
<Option value="+1246">+1 (246) Barbados</Option>
<Option value="+375">+375 Belarus</Option>
<Option value="+32">+32 Bélgica</Option>
<Option value="+501">+501 Belice</Option>
<Option value="+229">+229 Benín</Option>
<Option value="+1441">+1 (441) Bermuda</Option>
<Option value="+975">+975 Bután</Option>
<Option value="+591">+591 Bolivia</Option>
<Option value="+387">+387 Bosnia/Herzegovina</Option>
<Option value="+267">+267 Botsuana</Option>
<Option value="+55">+55 Brasil</Option>
<Option value="+1284">+1 (284) Islas Vírgenes Británicas</Option>
<Option value="+673">+673 Brunéi</Option>
<Option value="+359">+359 Bulgaria</Option>
<Option value="+226">+226 Burkina Faso</Option>
<Option value="+257">+257 Burundi</Option>
<Option value="+855">+855 Camboya</Option>
<Option value="+237">+237 Camerún</Option>
<Option value="+238">+238 Islas de Cabo Verde</Option>
<Option value="+1345">+1 (345) Islas Caimán</Option>
<Option value="+236">+236 República Centroafricana</Option>
<Option value="+235">+235 Chad</Option>
<Option value="+56">+56 Chile</Option>
<Option value="+86">+86 China</Option>
<Option value="+57">+57 Colombia</Option>
<Option value="+269">+269 Comoras</Option>
<Option value="+243">+243 Congo DROC, República Democrática del</Option>
<Option value="+242">+242 Congo ROC, República de</Option>
<Option value="+682">+682 Islas Cook</Option>
<Option value="+506">+506 Costa Rica</Option>
<Option value="+385">+385 Croacia</Option>
<Option value="+53">+53 Cuba</Option>
<Option value="+357">+357 Chipre</Option>
<Option value="+420">+420 República Checa</Option>
<Option value="+45">+45 Dinamarca</Option>
<Option value="+246">+246 Diego García</Option>
<Option value="+253">+253 Yibuti</Option>
<Option value="+1767">+1 (767) Dominica</Option>
<Option value="+1809/829/849">+1 (809/829/849) República Dominicana</Option>
<Option value="+593">+593 Ecuador</Option>
<Option value="+20">+20 Egipto</Option>
<Option value="+503">+503 El Salvador</Option>
<Option value="+240">+240 Guinea Ecuatorial</Option>
<Option value="+291">+291 Eritrea</Option>
<Option value="+372">+372 Estonia</Option>
<Option value="+251">+251 Etiopía</Option>
<Option value="+500">+500 Islas Malvinas</Option>
<Option value="+298">+298 Islas Feroe</Option>
<Option value="+679">+679 Fiyi</Option>
<Option value="+358">+358 Finlandia</Option>
<Option value="+33">+33 Francia</Option>
<Option value="+594">+594 Guyana Francesa</Option>
<Option value="+241">+241 Gabón</Option>
<Option value="+220">+220 Gambia</Option>
<Option value="+995">+995 Georgia</Option>
<Option value="+49">+49 Alemania</Option>
<Option value="+233">+233 Ghana</Option>
<Option value="+350">+350 Gibraltar</Option>
<Option value="+30">+30 Grecia</Option>
<Option value="+299">+299 Groenlandia</Option>
<Option value="+1473">+1 (473) Granada</Option>
<Option value="+590">+590 Guadalupe Antillas Francesas</Option>
<Option value="+502">+502 Guatemala</Option>
<Option value="+224">+224 Guinea</Option>
<Option value="+245">+245 Guinea-Bisáu</Option>
<Option value="+592">+592 Guyana</Option>
<Option value="+509">+509 Haití</Option>
<Option value="+504">+504 Honduras</Option>
<Option value="+852">+852 Hong Kong</Option>
<Option value="+36">+36 Hungría</Option>
<Option value="+354">+354 Islandia</Option>
<Option value="+91">+91 India</Option>
<Option value="+62">+62 Indonesia</Option>
<Option value="+98">+98 Irán</Option>
<Option value="+964">+964 Iraq</Option>
<Option value="+353">+353 Irlanda</Option>
<Option value="+972">+972 Israel</Option>
<Option value="+39">+39 Italia</Option>
<Option value="+225">+225 Costa de Marfil</Option>
<Option value="+1876">+1 (876) Jamaica</Option>
<Option value="+81">+81 Japón</Option>
<Option value="+962">+962 Jordania</Option>
<Option value="+7">+7 Kazajistán</Option>
<Option value="+254">+254 Kenia</Option>
<Option value="+686">+686 Kiribati</Option>
<Option value="+850">+850 Corea Norte</Option>
<Option value="+82">+82 Corea Sur</Option>
<Option value="+965">+965 Kuwait</Option>
<Option value="+996">+996 Kirguizistán</Option>
<Option value="+856">+856 Laos</Option>
<Option value="+371">+371 Letonia</Option>
<Option value="+961">+961 Líbano</Option>
<Option value="+266">+266 Lesoto</Option>
<Option value="+231">+231 Liberia</Option>
<Option value="+218">+218 Libia</Option>
<Option value="+423">+423 Liechtenstein</Option>
<Option value="+370">+370 Lituania</Option>
<Option value="+352">+352 Luxemburgo</Option>
<Option value="+853">+853 Macau</Option>
<Option value="+389">+389 Macedonia</Option>
<Option value="+261">+261 Madagascar</Option>
<Option value="+265">+265 Malaui</Option>
<Option value="+60">+60 Malasia</Option>
<Option value="+960">+960 Maldivas</Option>
<Option value="+223">+223 Malí</Option>
<Option value="+356">+356 Malta</Option>
<Option value="+692">+692 Islas Marshall</Option>
<Option value="+596">+596 Martinica</Option>
<Option value="+222">+222 Mauritania</Option>
<Option value="+230">+230 Islas Mauricio</Option>
<Option value="+52">+52 México</Option>
<Option value="+691">+691 Micronesia</Option>
<Option value="+373">+373 Moldova</Option>
<Option value="+377">+377 Mónaco</Option>
<Option value="+976">+976 Mongolia</Option>
<Option value="+382">+382 Montenegro</Option>
<Option value="+1664">+1 (664) Montserrat</Option>
<Option value="+212">+212 Marruecos</Option>
<Option value="+258">+258 Mozambique</Option>
<Option value="+95">+95 Myanmar Birmania</Option>
<Option value="+264">+264 Namibia</Option>
<Option value="+674">+674 Nauru</Option>
<Option value="+977">+977 Nepal</Option>
<Option value="+31">+31 Holanda</Option>
<Option value="+599">+599 Antillas Neerlandesas Bonaire, Curacao, Saba, St. Eustis</Option>
<Option value="+687">+687 Nueva Caledonia</Option>
<Option value="+64">+64 Nueva Zelanda</Option>
<Option value="+505">+505 Nicaragua</Option>
<Option value="+227">+227 Níger</Option>
<Option value="+234">+234 Nigeria</Option>
<Option value="+1670">+1 (670) Islas Marianas del Norte</Option>
<Option value="+47">+47 Noruega</Option>
<Option value="+968">+968 Omán</Option>
<Option value="+92">+92 Pakistán</Option>
<Option value="+680">+680 Palaos</Option>
<Option value="+970">+970 Autoridad Palestina</Option>
<Option value="+507">+507 Panamá</Option>
<Option value="+675">+675 Papúa Nueva Guinea</Option>
<Option value="+595">+595 Paraguay</Option>
<Option value="+51">+51 Perú</Option>
<Option value="+63">+63 Filipinas</Option>
<Option value="+48">+48 Polonia</Option>
<Option value="+351">+351 Portugal</Option>
<Option value="+974">+974 Qatar</Option>
<Option value="+262">+262 Reunión</Option>
<Option value="+40">+40 Rumania</Option>
<Option value="+7">+7 Rusia</Option>
<Option value="+250">+250 Ruanda</Option>
<Option value="+1670">+1 (670) Saipán Islas Marianas del Norte</Option>
<Option value="+685">+685 Samoa</Option>
<Option value="+378">+378 San Marino</Option>
<Option value="+239">+239 Santo Tomé/Príncipe</Option>
<Option value="+966">+966 Arabia Saudita</Option>
<Option value="+221">+221 Senegal</Option>
<Option value="+381">+381 Serbia</Option>
<Option value="+248">+248 Seychelles</Option>
<Option value="+232">+232 Sierra Leona</Option>
<Option value="+65">+65 Singapur</Option>
<Option value="+1 721">+1 (721) Isla de San Martín</Option>
<Option value="+421">+421 Eslovaquia</Option>
<Option value="+386">+386 Eslovenia</Option>
<Option value="+677">+677 Islas Salomón</Option>
<Option value="+27">+27 Sudáfrica</Option>
<Option value="+211">+211 Sudán Meridional</Option>
<Option value="+34">+34 España</Option>
<Option value="+94">+94 Sri Lanka</Option>
<Option value="+508">+508 San Pedro/Miquelón</Option>
<Option value="+1869">+1 (869) San Cristóbal/Nieves</Option>
<Option value="+1758">+1 (758) Santa Lucía</Option>
<Option value="+1784">+1 (784) San Vicente/Granadinas</Option>
<Option value="+249">+249 Sudán</Option>
<Option value="+597">+597 Suriname</Option>
<Option value="+268">+268 Suazilandia</Option>
<Option value="+46">+46 Suecia</Option>
<Option value="+41">+41 Suiza</Option>
<Option value="+963">+963 Siria</Option>
<Option value="+886">+886 Taiwán</Option>
<Option value="+992">+992 Tayikistán</Option>
<Option value="+255">+255 Tanzania</Option>
<Option value="+66">+66 Tailandia</Option>
<Option value="+228">+228 Togo</Option>
<Option value="+690">+690 Tokelau</Option>
<Option value="+676">+676 Tonga</Option>
<Option value="+1868">+1 (868) Trinidad y Tobago</Option>
<Option value="+216">+216 Túnez</Option>
<Option value="+90">+90 Turquía</Option>
<Option value="+993">+993 Turkmenistán</Option>
<Option value="+1649">+1 (649) Islas Turcas y Caicos</Option>
<Option value="+688">+688 Tuvalu</Option>
<Option value="+256">+256 Uganda</Option>
<Option value="+380">+380 Ucrania</Option>
<Option value="+971">+971 Emiratos Árabes Unidos</Option>
<Option value="+44">+44 Reino Unido</Option>
<Option value="+598">+598 Uruguay</Option>
<Option value="+998">+998 Uzbekistán</Option>
<Option value="+678">+678 Vanuatu</Option>
<Option value="+58">+58 Venezuela</Option>
<Option value="+84">+84 Vietnam</Option>
<Option value="+967">+967 Yemen</Option>
<Option value="+260">+260 Zambia</Option>
<Option value="+263">+263 Zimbabue</Option>
          </Select>
        </Form.Item>
      );

    return (
        <Row className="contenedor_registro">
            <Col xs={{span:24}} md={{span:24}}>
            <Spin spinning={cargando_login}>
            {!ingresar_codigo ?
            (
              <Form className="login-form" 
                    onFinish={onFinish} 
                    ref={ref_form_login}
                    layout="vertical"
                    initialValues={{
                      remember: true,
                      prefix: '+52',
                      }} >
              <Row>
 <Col span={24}>
          <span className="color_naranja" style={{color:'#fe633f'}}>*Número celular</span>
            </Col>
            <Col xs={{span:24}} md={{span:8}}>
            {prefixSelector}
              </Col>
              <Col xs={{span:24}} md={{span:16}}>
              <Form.Item
        name="telefono"
        label={null}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa un número válido</span>,
          },
        ]}
      >
        <Input
          style={{
            width: '100%',
            backgroundColor:'white'
          }}
        />
      </Form.Item></Col>
      <Col span={24}>
      {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                        <Form.Item>
                            <Row>
                                
                                <Col span={24} style={{textAlign:'center'}}>
                                <br />
                                    <Button id="sign-in-button" className="boton_recibir_codigo" htmlType="submit">Recibir código</Button>
                                    
                                </Col>
                            </Row>
                            
                        </Form.Item>
      </Col>
                </Row>
                
                        
                        
                        
                        

                    </Form>
            
            )
            :
            (
                <Row>
                    <Col span={24}>
                <p style={{textAlign:'center', color:'#fe633f'}}><br />Ingresa el código que llegó a tu teléfono</p>
            </Col>
            <Col span={18}>
                <Input style={{width:'100%'}} ref={ref_codigo} />
            </Col>
            <Col span={6}>
                <Button className="boton_recibir_codigo" style={{width:'100%'}} onClick={colocarCodigo}>Verificar</Button>
            </Col>
            <Col span={24}>
            <br />
                <span>El código te llegará vía SMS al teléfono registrado. Esto podría tardar unos minutos, por lo que te pedimos no cerrar ni actualizar la página.


                </span>
                <br />
<p style={{textAlign:'center'}}>
<a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=No%20percibo%20el%20código%20SMS%20en%20Scania%20Digital%20Forum">Da clic aquí si no recibes tu código</a>

</p>
                
                </Col>
                </Row>
            )
            }
</Spin>
</Col>
            <Col span={24}>
            <p style={{textAlign:'center'}}>
                <br />
                                    <span>¿No tienes cuenta? </span>
                                    <br />
                                    <Link to="/registro" onClick={() => { onResponse();}}><button className="boton_registrate" style={{backgroundColor:'white', border:'1px solid white', padding:'0px', cursor:'pointer'}} >
                                      Regístrate
                                    </button>
                                    </Link>
                                    <br /><br />
                                    <Link to="/en-vivo" className="boton_registrate" onClick={()=>onResponse()} style={{backgroundColor:'white', border:'1px solid white', padding:'0px', cursor:'pointer'}} >
                                      Entrar sin login
                                    </Link>

                                    </p>
            </Col>
            

           
        
        </Row>

    );
}

export default Login;
