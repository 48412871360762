import React from 'react';
import { Row, Col } from 'antd';
import {Link } from 'react-router-dom';

import Header from '../master/Header';
import Footer from '../master/Footer';

import banner from '../../assets/images/sitio/40D47F9E28414EE0AB2CDCA76821AB56.png';

import revivir from '../../assets/images/sitio/home/btn-revivelo_webinars.png';
import presen from '../../assets/images/sitio/home/btn-presentaciones.png';
import pasado from '../../assets/images/sitio/home/etiqueta_ webinarpasado.png';
import conf_pasada from '../../assets/images/sitio/home/lanzamiento_pasado.jpg';


import img4 from '../../assets/images/sitio/home/minivistaw-gestioria.jpg';
import img5 from '../../assets/images/sitio/home/minivistaw-invierteencapacitacion.jpg';
import img6 from '../../assets/images/sitio/home/minivistaw-ventajasentuoperacion.jpg';
import img51 from '../../assets/images/sitio/home/minivistaw-flotautobuses.jpg';
import img7 from '../../assets/images/sitio/home/minivistaw-mejoraelrendimiento.jpg';
import img8 from '../../assets/images/sitio/home/minivistaw-comocuidartusautobuses.jpg';
import img9 from '../../assets/images/sitio/home/minivistaw-FOCUS-RESET.jpg';
import img2 from '../../assets/images/sitio/home/minivistaw-recomendaciones.jpg';
import agosto13 from '../../assets/presentaciones/SCANIA_GESTION_EN_EL_TALLER.pdf';

import '../../assets/css/conferencia.css';
import CarouselLanzamientos from '../evento/CarouselLanzamientos';

const conferencias = [
  {anio:'2022', fecha:'07 JUNIO', live:'', nombre:'Lanzamiento Power solutions Motores', descripcion:'', dia:'1', img:img9, url:'https://youtu.be/cGigNMdMR-4', link:revivir, presencaiones:'',pas:conf_pasada,descargar:'../../../public/presentaciones', clas:''},
  {anio:'2022', fecha:'01 JUNIO', live:'', nombre:'Nuväk la nueva generación de Chasis', descripcion:'', dia:'1', img:img9, url:'https://youtu.be/IHGwfxVue-E', link:revivir, presencaiones:'',pas:conf_pasada,descargar:'../../../public/presentaciones', clas:''},
  {anio:'2022', fecha:'21 ABRIL', live:'', nombre:'Lanzamiento de Camión Eléctrico', descripcion:'', dia:'1', img:img9, url:'https://youtu.be/FYDz8_0Pu5k', link:revivir, presencaiones:'',pas:conf_pasada,descargar:'../../../public/presentaciones', clas:''},
  {anio:'2021', fecha:'22 SEPTIEMBRE', live:'', nombre:'Lanzamiento de Scania Volt & E Urviabus', descripcion:'', dia:'1', img:img9, url:'https://youtu.be/v3EIZKzJVzo', link:revivir, presencaiones:'',pas:conf_pasada,descargar:'../../../public/presentaciones', clas:''},
  {anio:'2021', fecha:'25 MARZO', live:'', nombre:'Lanzamiento del camión Scania euro 6 diésel', descripcion:'', dia:'1', img:img9, url:'https://youtu.be/AZ39wAEhy0o', link:revivir, presencaiones:'',pas:conf_pasada,descargar:'../../../public/presentaciones', clas:''},
  ];


const Lanzamientos = () => {


    let visualizar_conferencias = conferencias.map((conferencia)=>{
        return(
          <div className="info-horario_conf">
            
            <Row justify="center" align="middle">
              <Col xs={{span:20, offset:2}} md={{span:3, offset:0}}>
                <p style={{ textAlign:'center', marginBottom: '0em' }}>
                  <img alt="" style={{ width:'50%' }} src={conferencia.live}/><br/>
                  <span className="info-fecha_conf">{conferencia.fecha}</span></p>
                  <p style={{ textAlign:'center' }}>
                  <span className="info-anio_conf">{conferencia.anio}</span>
                  </p>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:1}} className="sec-titulo_conf">
                <p className="info-completa_conf">
                <span className="info-nombre_conf">{conferencia.nombre}</span><br />
                <img className="web-pasado_conf" src={conferencia.pas} />
                <br />
                </p>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:4, offset:0}} >
                <a href={conferencia.url} target="_blank">
                <img src={"https://img.youtube.com/vi/"+conferencia.url.substring(17,28)+"/mqdefault.jpg"} className="info-video_conf" style={{ width:'100%' }}/>
                </a>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:4, offset:0}} className={conferencia.clas}>
                <a href={conferencia.url} target="_blank">
                  <img  style={{ width:'70%' }} src={conferencia.link}/>
                </a><br />
        
                <a href={conferencia.descargar} download>
                  <img alt="" style={{ width:'70%' }} src={conferencia.presencaiones}/>
                </a>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:22, offset:2}} >
                <hr style={{ color:'#000000' }} />
              </Col>
            </Row>
          </div>
        );
      });


    return(
      <>
      <Row className="contenedor_conferencias">
      <Col xs={0} md={0}>
                <Link to="/registro">
                <img src={banner} style={{ width:'100%' }} />
                </Link>
            </Col>
        <Col span={24}>
          <br />
          <p style={{color:'white', fontSize:'40px'}}>LANZAMIENTOS</p>
        </Col>
        <Col span={0} style={{textAlign:'center'}}>
          <CarouselLanzamientos />
        </Col>
        
        <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:14, offset:4}} lg={{span:16, offset:4}} style={{textAlign:'center', display:'non'}}>
          <div className="contenedor_video_conferencia">
          <iframe src="https://www.youtube.com/embed/hIPy6Oi2bQk?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Col>
        <Col span={24}>
          <br /><br /><br />
        </Col>
        <Col span={20} offset={2} className="contenedor_programas">
        {visualizar_conferencias}
        </Col>
        <Col span={24}>
          <br /><br /><br />
        </Col>
      </Row>
      
        </>
    );
}

export default Lanzamientos;