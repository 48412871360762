import React, { Component, useRef } from "react";
import Slider from "react-slick";
import '../../assets/css/ingenieria/evento.css';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Contador from  './Contador';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import banner_principal from '../../assets/images/eventos/my_scania/16_9_Master Graphic My Scania 1600x716.png';
import boton_registrar from '../../assets/images/eventos/foro_inclusion/btn_registrate.png';
import boton_registrar_movil from '../../assets/images/eventos/ganadores_transporte_mexico/MOVIL/btn_registrate.png';
import boton_registrar_dos from '../../assets/images/eventos/lanzamiento-camion-scania-6/btn_transmision.png';

  const CarouselPrincipal = () => {

    const ref_carousel = useRef(null);

    const cambiarCarousel = (dir) => {

      if(dir){
          ref_carousel.current.slickNext();
      }else{
        ref_carousel.current.slickPrev();
      }
    }

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1,
      autoplaySpeed: 5000,
      cssEase: "linear",
      
    };
    const settingsNoModules = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false
    };
    
    
    return (
      <div>
        <Row align="middle">
        <Col span={0}>
        <LeftOutlined className="flecha_carousel_webinar" onClick={()=>{cambiarCarousel(false)}} />
        </Col>
          <Col span={24}>
          <Slider ref={ref_carousel} {...settingsNoModules}>
        <div>
          <Row justify="center" align="middle" style={{position:'relative'}}>
            <Col span={24} style={{position:'relative'}}>
              <Link to="/registro" >
              <img src={banner_principal} className="banner_principal" />
              </Link>
            </Col>
            <Col span={6} className="contenedor_registro_banners" style={{display:'none'}}>
              <Link to="/registro" >
                <img style={{width:'100%'}} src={boton_registrar} />
              </Link>
            </Col>
          </Row>
          
        </div>



        


        </Slider>

          </Col>
          <Col span={0}>
          <RightOutlined className="flecha_carousel_webinar" onClick={()=>{cambiarCarousel(true)}} />
            </Col>
            <Col span={0} style={{paddingTop:'5%', zIndex:'-3'}}>
            </Col>
        </Row>
        
      </div>
    );
  }
  export default CarouselPrincipal;