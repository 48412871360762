import React, { useParams, useContext, useRef, useState, useEffect } from 'react';
import { } from 'react-router-dom';
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from 'antd';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

import '../../assets/css/sala.css';
import logoS from '../../assets/images/sitio/envivo/logo scania_preguntas.png';
import imagen_proximamente from '../../assets/images/eventos/volver_al_futuro/voltealafuturo_1920_scania.jpg';

import img_sin_sesion from '../../assets/images/eventos/inicia_sesion.jpg';

import PopUpPregunta from './PopUpPregunta';

import rede_facebook from '../../assets/images/sitio/envivo/logo-fb_azul.png';
import rede_ln from '../../assets/images/sitio/envivo/logo-in_azul.png';
import rede_youtube from '../../assets/images/sitio/envivo/logo-yt_azul.png';

import img_espera from '../../assets/images/sitio/envivo/proximamente.png';

const { TextArea } = Input;
const { TabPane } = Tabs;

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const layout = {
  wrapperCol: {
    span: 24,
  },
};

const titulo_webinar = "Técnic@s del futuro";

const Live = (props) => {

  const [mis_preguntas, misPreguntas] = useState(true);
  const [en_vivo, enVivo] = useState(false);
  const [preguntas_contestadas, preguntasContestadas] = useState(false);

  const [modal_cuestionario, setModalCuestionario] = useState(false);
  const [num_pregunta, setNumPregunta] = useState(0);
  const { url, comentarios, webinar, urlfb, urlyt, urlln, onResponseMostrarLogin, dia, respuestas, ver_video } = props;
  const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});

  const { usuario, datos } = useContext(Auth);

  const ref_form_pregunta = useRef();

  useEffect(() => {
    if (usuario) {
      console.log(datos);
      app.obtener_objeto(webinar + '/respuestas-usuarios-en-vivo', 'telefono', datos.telefono, (data) => {
        setPreguntasPopUpUsuario(data);
      });
    }
  }, [usuario, dia]);

  useEffect(() => {

    if (Object.keys(preguntas_popup_usuario).length !== 0) {

      app.obtener_objetos(webinar + '/configuracion', true, (data) => {

        setNumPregunta(data[0].pregunta_popup);

        if (data[0].pregunta_popup === 0) {
          setModalCuestionario(false);
        } else {
          if (preguntas_popup_usuario['respuesta' + data[0].pregunta_popup] === undefined || preguntas_popup_usuario['respuesta' + data[0].pregunta_popup] === null) {
            setModalCuestionario(true);
          } else {
            setModalCuestionario(false);
          }

        }

      });

    }

  }, [preguntas_popup_usuario]);

  const onResponseCuestionario = (valor_respuesta) => {
    setModalCuestionario(false);

    preguntas_popup_usuario['respuesta' + num_pregunta] = valor_respuesta;

    app.escribir(webinar + `/respuestas-usuarios-en-vivo${dia}`, preguntas_popup_usuario.key, preguntas_popup_usuario, (item) => {
      mensaje('success', 'Gracias por tu participación', '');
    });

  }


  let visualizar_preguntas = comentarios.filter((pre) => {
    if (pre.status === 'aceptada' && pre.ver_video == ver_video) {
      return true;
    } else {
      return false;
    }
  }).map((pre) => {

    let answers = respuestas.filter(answer => answer.key_pregunta === pre.key).map(answer => {
      return (
        <div className="balloon_respuesta">
          <p className="texto-respuetas">
            <span className="text-pre">{answer.respuesta}</span>
          </p>
        </div>
      );
    });

    return (
      <>
        <div className="balloon">
          <p className="texto-preguntas">
            <span className="text-pre">{pre.pregunta}</span><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.empresa}</span></strong>
          </p>
        </div>
        {answers}
      </>
    );
  });

  let ver_mispreguntas = comentarios.filter((pre) => {
    if (pre.correo === datos.correo && pre.ver_video === ver_video) {
      return true;
    } else {
      return false;
    }
  }).map((seccion) => {

    let answers = respuestas.filter(answer => answer.key_pregunta === seccion.key).map(answer => {
      return (
        <div className="balloon_respuesta">
          <p className="texto-respuetas">
            <span className="text-pre">{answer.respuesta}</span>
          </p>
        </div>
      );
    });

    return (
      <>
        <div className="balloon">
          <p className="texto-preguntas">
            <span className="text-pre">{seccion.pregunta}</span><br />
            <strong><span className="text-nom">{seccion.status}</span></strong>
          </p>
        </div>
        {answers}
      </>
    );
  });

  let ver_contestadas = comentarios.filter((pre) => {
    if (pre.status === 'contestada' && pre.ver_video == ver_video) {
      return true;
    } else {
      return false;
    }
  }).map((pre) => {

    let answers = respuestas.filter(answer => answer.key_pregunta === pre.key).map(answer => {
      return (
        <>
          <div className="balloon_respuesta">
            <p className="texto-respuetas">
              <span className="text-pre">{answer.respuesta}</span>
            </p>
          </div>

        </>
      );
    });

    return (
      <>
        <div className="balloon">
          <p className="texto-preguntas">
            <span className="text-pre">{pre.pregunta}</span><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.empresa}</span></strong>
          </p>
        </div>
        {answers}
      </>
    );
  });

  const onFinish = values => {

    let pregunta = {
      empresa: datos.empresa,
      organizacion: datos.empresa,
      nombre: datos.nombre + ' ' + datos.apellidos,
      ponente: (values.ponente === '' || values.ponente === ' ') ? '...' : values.ponente,
      pregunta: values.pregunta,
      correo: datos.correo,
      ver_video: parseInt(ver_video),
      status: 'pendiente'
    }

    app.escribir(webinar + `/preguntas-publico${dia}`, undefined, pregunta, () => {
      mensaje('success', 'Tu pregunta ha sido enviada');
      ref_form_pregunta.current.resetFields();
    });
  };


  return (
    <Row align="middle" className="contenedor_liv" >

      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 2 }} style={{ textAlign: 'center' }}>{/* 14,*/}

        {url !== null ?
          (
            <>
              <p className="titulo_webinar" style={{ textAlign: 'center' }}>
                <span className="titulo_webinar_1"></span>
                <span className="titulo_webinar_2"> {titulo_webinar}</span>
              </p>


              <div className="contenedor_video" style={{ display: 'non' }}>
                <iframe className="video" src={`${url}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </>
          ) :
          (
            <>
              <p className="titulo_webinar">
                <span className="titulo_webinar_1">WEBINAR </span>
                <span className="titulo_webinar_2"> {titulo_webinar}</span>
              </p>
              <div className="contenedor_video">
                <img className="video" src={imagen_proximamente} />

              </div>


            </>
          )


        }
{url !== null ?
        (<>
          <Col xs={0} lg={{ span: 24, offset: 0 }} >
            <p className="clic_ver">No olvides verificar que tu red te permita reproducir contenido de YouTube, si no puedes ver el video da clic
              <a href="https://api.whatsapp.com/send?phone=525557400333&text=No%20puedo%20ver%20la%20tranmisi%C3%B3n%20en%20Scania%20Digital%20Forum" target="_blank" className="clic_ver_red_social"> aquí.</a>

              {urlfb !== null ?
                (<>
                  <a href={urlfb} target="_blank" className="clic_ver_red_social">, Facebook</a>
                </>)
                :
                (
                  <>
                  </>
                )
              }
              {urlln !== null ?
                (<>
                  <a href={urlln} target="_blank" className="clic_ver_red_social"> o  Linkedin</a>
                </>)
                :
                (
                  <>
                  </>
                )
              }
            </p>

          </Col>
        </>)
        :
        (
          <>
          </>
        )
      }

      </Col>
      {url !== null ?
        (<>
          <Col xs={{ span: 20, offset: 2 }} lg={0}>
            <p className="clic_ver">No olvides verificar que tu red te permita reproducir contenido de YouTube, si no puedes ver el video da clic
              <a href="https://api.whatsapp.com/send?phone=525557400333&text=No%20puedo%20ver%20la%20tranmisi%C3%B3n%20en%20Scania%20Digital%20Forum" target="_blank" className="clic_ver_red_social"> aquí.</a>
              {urlfb !== null ?
                (<>
                  <a href={urlfb} target="_blank" className="clic_ver_red_social"> , Facebook</a>
                </>)
                :
                (
                  <>
                  </>
                )
              }
              {urlln !== null ?
                (<>
                  <a href={urlln} target="_blank" className="clic_ver_red_social"> o Linkedin</a>
                </>)
                :
                (
                  <>
                  </>
                )
              }
            </p>
          </Col>
        </>)
        :
        (
          <>
          </>
        )
      }
      <Col xs={24} lg={0} style={{ display: 'non' }}>
        <br />
      </Col>
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 0 }} className="contenedor_live_preguntas" style={{ display: 'non' }}>
        <Row justify="">
          <Col xs={{ span: 24 }} lg={{ span: 0 }}><br /></Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 23, offset: 1 }} className="header-preguntas">
            <Row align="middle">
              <Col span={18} offset={1}>
                <p className="pregunta-p"><strong>PREGUNTAS</strong>
                </p>
              </Col>
              <Col span={4}>
                <img alt="logoS" src={logoS} style={{ width: '50px', padding: '0px, 0px, 0px, 0px' }} />
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={{ span: 24, offset: 0 }} lg={{ span: 23, offset: 1 }}>
            <Row>
              <Col span={8} className={mis_preguntas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
                <p style={{ marginBottom: '0px' }} onClick={() => (misPreguntas(true), enVivo(false), preguntasContestadas(false))}>Mis preguntas</p>
              </Col>
              <Col span={8} className={en_vivo === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
                <p style={{ marginBottom: '0px' }} onClick={() => (enVivo(true), misPreguntas(false), preguntasContestadas(false))}>En vivo</p>
              </Col>
              <Col span={8} className={preguntas_contestadas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
                <p style={{ marginBottom: '0px' }} onClick={() => (enVivo(false), misPreguntas(false), preguntasContestadas(true))}>Contestadas</p>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={{ span: 24, offset: 0 }} lg={{ span: 23, offset: 1 }} className="ver-preguntas" >
            <Row>
              <Col span={24}>
                {mis_preguntas && (
                  <div>
                    {ver_mispreguntas}
                  </div>
                )}
                {en_vivo && (
                  <div>
                    {visualizar_preguntas}</div>
                )}
                {preguntas_contestadas && (
                  <div>
                    {ver_contestadas}</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={0}  >
            <Row>
              <Col span={24} className="tipo_preguntas_select">
                <p style={{ marginBottom: '0px' }}>En vivo</p>
              </Col>
              <Col span={24} className="ver-preguntas">
                <div>
                  {visualizar_preguntas}</div>
              </Col>
            </Row>
          </Col>

          <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 23, offset: 1 }} className="contenedor_enviar">

            {usuario ?
              <>

                <Form
                  {...layout}
                  name="basic"
                  initialValues={{
                    pregunta: '',
                    remember: true,
                  }}
                  onFinish={onFinish}
                  ref={ref_form_pregunta}
                >
                  <Row>
                    <Col span={22} offset={1} >
                      <Form.Item

                        name="ponente"
                        rules={[
                          {
                            required: false,
                            message: 'Por favor escribe a que ponente va dirigida tu pregunta',
                          },
                        ]}
                      >
                        <Input className="text_enviar" placeholder={'¿A quien va dirigida tu pregunta?'} />
                      </Form.Item>
                    </Col>
                    <Col span={22} offset={1} >
                      <Form.Item

                        name="pregunta"
                        rules={[
                          {
                            required: true,
                            message: 'Por favor escribe una pregunta',
                          },
                        ]}
                      >
                        <TextArea className="text_enviar" disabled={usuario ? false : true} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 15, oofset: 1 }} md={{ span: 15, offset: 1 }}  >
                      <p className="sujeto" style={{ padding: '5px 0px 0px 5px', fontFamily: 'Scania Regular' }}>


                        <span>* Todas las preguntas están sujetas a aprobación<br /><br /></span>



                      </p>
                    </Col>
                    <Col xs={{ span: 7, offset: 1 }} style={{ textAlign: 'center', paddingTop: '5px' }}>
                      <Form.Item >
                        <Button type="" htmlType="submit" className="btn-enviar" disabled={usuario ? false : true }>{/**usuario ? false : true */}
                          Enviar
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

              </>
              :
              <>
                <img src={img_sin_sesion} style={{ width: '100%', height: '', cursor: 'pointer' }} onClick={() => { onResponseMostrarLogin(true); }} />
              </>
            }



          </Col>

        </Row>
      </Col>
      
      <Col xs={24} lg={0}>
        <br /><br />
      </Col>
      <Modal
        title={<span style={{ color: '#fe633f', textAlign: 'center', fontWeight: 'bold' }}>PARTICIPA EN NUESTRA ENCUESTA DE SATISFACCIÓN</span>}
        visible={modal_cuestionario}
        footer={null}
        onCancel={() => setModalCuestionario(false)}
      >

        <PopUpPregunta num_pregunta={num_pregunta} onResponse={onResponseCuestionario} />


      </Modal>
    </Row>
  );
}

export default Live;