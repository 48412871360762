import React, {useContext} from 'react';
import { Row, Col, Form, Input, Button, Modal, Select, Spin, message, notification } from "antd";
import  Firebase  from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import '../../assets/css/registro.css';

const app = new Firebase();
const nombre_webinar = 'volver_futuro';

const mensaje = (tipo,titulo, texto) =>{

    notification[tipo]({
        message: titulo,
        description: texto,
    });

}

const LoginSinRegistro = ({ history, onResponse, onResponseLogin }) => {

    const { usuario } = useContext(Auth);

    const onFinish = async values => {

        values.nombre_curso = nombre_webinar;

        localStorage.setItem('usuario',JSON.stringify(values));

        app.escribir(nombre_webinar+'/usuarios',undefined, values,(data)=>{
            app.app.auth().signInAnonymously()
            .then(() => {
                mensaje('success','Bienvenido','');
                onResponseLogin();
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
        });
        });
    };

    return(
        <Form className="login-form" 
                    onFinish={onFinish} 
                    layout="vertical"
                    /*initialValues={{nombre:'Martin',correo:'martin@thethree', empresa:'The Three'}}*/
                    >
                        <Form.Item
        name="nombre"
        label={<span className="color_naranja" style={{color:'#fe633f'}}>Nombre</span>}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu nombre</span>,
          },
        ]}
      >
        <Input
          style={{
            width: '100%',
            backgroundColor:'white'
          }}
        />
      </Form.Item>

      <Form.Item
        name="correo"
        label={<span className="color_naranja" style={{color:'#fe633f'}}>Correo</span>}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu correo</span>,
          },
        ]}
      >
        <Input
          style={{
            width: '100%',
            backgroundColor:'white'
          }}
        />
      </Form.Item>
      <Form.Item
        name="empresa"
        label={<span className="color_naranja" style={{color:'#fe633f'}}>Empresa</span>}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu empresa</span>,
          },
        ]}
      >
        <Input
          style={{
            width: '100%',
            backgroundColor:'white'
          }}
        />
      </Form.Item>
                        
                        <Form.Item>
                            <Row>
                                
                                <Col span={24} style={{textAlign:'center'}}>
                                <br />
                                    <Button id="sign-in-button" className="boton_recibir_codigo" htmlType="submit">Entrar</Button>
                                    
                                </Col>
                            </Row>
                            
                        </Form.Item>

                    </Form>
    );
}

export default LoginSinRegistro;
