import React, { useState, useContext, useEffect, useRef } from "react";
import Registrocompleto from '../RegistroCompleto';
import '../../../assets/css/login.css';
import {Auth} from '../../../utils/AuthContext';

import banner from '../../../assets/images/eventos/valores_operacion/bannerRegistro_valoroperacion_3.jpg';
import banner_movil from '../../../assets/images/eventos/propuestas_de_valor/REGISTRO/bannerRegistro_movil_3.jpg';

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber
} from 'antd';
import  Firebase  from "../../../utils/firebaseConfig";
import  { mail_registro_confirmacion } from "../../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_crear from '../../../assets/images/registro/btn_registro.png';
import img_camion from '../../../assets/images/sitio/camion_scania_negro.png';
import wts from '../../../assets/images/sitio/registro/problemasconregistro_daclick.png';
import boton_ya_cuenta from '../../../assets/images/registro/btn_yacuentocon-74.png';
import boton_ya_cuenta_movil from '../../../assets/images/registro/btn_yacuentocon_movil.png';

import LoginExiste from '../../registro/LoginExiste';

const openNotificationWithIcon = (type,titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 0,
  });
};

const nom_evento = "volver_futuro";

const { Option } = Select;

let correo_registrado = '', password_registrado = '';

const app = new Firebase();

const success = () => {
  openNotificationWithIcon('success','Bienvenido','');
};

const Registro = ({ setsignup, history }) => {
    const [error, seterror] = useState("");
    const [otro_medio, setOtroMedio] = useState(false);
    const [confirmacion, setConfirmacion] = useState(false);
    const [cargando_regsitro, setCargandoRegistro] = useState(false);
    const { usuario, datos } = useContext(Auth);
    const [mostrar_login, setMostrarLogin] = useState(false);
    const [login_econtrado, setLoginEncontrado] = useState(false);
    const [reutilizar_datos, setReutilizarDatos] = useState(false);

    const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_recaptcha = React.createRef();
    

    const elegirMedio = value => {
      console.log(value);

      if(value === 'Otro'){
        setOtroMedio(true);
      }else{
        setOtroMedio(false);
      }

    }

    useEffect(()=>{
      if(usuario){
        app.app.auth().signOut().then(function() {
        
        }).catch(function(error) {
          // An error happened.
        });
        datos = {};
      }
    },[]);

    useEffect(() => {
     
        if(reutilizar_datos){
          
          if(Object.keys(datos).length > 2 ){
            correo_registrado = datos.correo;
           
            datos.scania_connect = 'registrado';
      datos.nombre_curso = nom_evento;
      datos.apellidos = datos.ap_paterno+' '+datos.ap_materno;

            mail_registro_confirmacion(datos).then(({data,status}) => {
              switch(status){
                case 0:
                  
                  //delete values.aceptar_terminos;
                  app.escribir(nom_evento+'/usuarios',undefined,datos, () => {
                    
                  });
                  
                  openNotificationWithIcon('success','',data);
                  setConfirmacion(true);
                break;
                default:
                  openNotificationWithIcon('warning','',data);
                  setConfirmacion(false);
                  break;
              }
              //setCargandoRegistro(false);
              setMostrarLogin(false);
            });
            setReutilizarDatos(false);

          }else{
            console.log('no existen los dtos');
          }
        }
    }, [datos, reutilizar_datos]);

    const registrarLoginExiste = (medio)=> {
      setReutilizarDatos(true);
    }

    const onFinish = async values => {

      setCargandoRegistro(true);

      let recaptcha = 'sin-recaptcha'; //ref_recaptcha.current.getValue();

        const { prefix, telefono, correo } = values;

        let contra = values.password;

        correo_registrado = correo;

        window.fbq('track', 'Lead');

        values.telefono = values.prefix+''+values.telefono;
        values.scania_connect = 'registrado';

        values.nombre_curso = nom_evento;
              
              if(values.medio_enterado === 'Otro'){
                values.medios = values.otro_medio;
                delete values.otro_medio;
              }else{
                values.medios = values.medio_enterado;
              }

              values.nombre1 = values.nombre;
              values.apellidos = values.ap_paterno+' '+values.ap_materno;
              
              values.procedencia = '';
              values.fecha_nacimiento = '';
              values['g-recaptcha-response'] = recaptcha;
              values.otro_procedencia = values.empresa;
              values.pass = contra;
              values.scania_connect = 'registrado';
              mail_registro_confirmacion(values).then(({data,status}) => {
                switch(status){
                  case 0:
                    console.log(values);
                    delete values.medios;
                    delete values.pass;
                    //delete values.aceptar_terminos;
                    app.escribir(nom_evento+'/usuarios',undefined,values, () => {
                      
                    });
                    app.escribir('usuarios',undefined,values, () => {
                      
                    });
                    
                    openNotificationWithIcon('success','',data);
                    setConfirmacion(true);
                  break;
                  default:
                    openNotificationWithIcon('warning','',data);
                    setConfirmacion(false);
                    break;
                }
                setCargandoRegistro(false);
                
                
              });
      };

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 110,
            }}
          >
            <Option value="+500" >+500 FK</Option>
            <Option value="+501" >+501 BZ</Option>
            <Option value="+502" >+502 GT</Option>
            <Option value="+503" >+503 SV</Option>
            <Option value="+504" >+504 HN</Option>
            <Option value="+505" >+505 NI</Option>
            <Option value="+506" >+506 CR</Option>
            <Option value="+507" >+507 PA</Option>
            <Option value="+508" >+508 PM</Option>
            <Option value="+509" >+509 HT</Option>
            <Option value="+51" >+51 PE</Option>
            <Option value="+52" >+52 MX</Option>
            <Option value="+53" >+53 CU</Option>
            <Option value="+54" >+54 ARG</Option>
            <Option value="+55" >+55 BR</Option>
            <Option value="+56" >+56 CL</Option>
            <Option value="+57" >+57 CO</Option>
            <Option value="+58" >+58 VE</Option>
            <Option value="+590" >+590 GP</Option>
            <Option value="+591" >+591 BO</Option>
            <Option value="+593" >+593 EC</Option>
            <Option value="+594" >+594 GF</Option>
            <Option value="+549" >+549 PY</Option>
            <Option value="+595" >+595 PY</Option>
            <Option value="+596" >+596 MQ</Option>
            <Option value="+597" >+597 SR</Option>
            <Option value="+598" >+598 UY</Option>
            <Option value="+809" >+809 DO</Option>
          </Select>
        </Form.Item>
      );

    return (
      <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
      
        <Row>
        <Col xs={0} md={24}>
                <img src={banner} style={{ width:'100%' }} />
            </Col>
            <Col xs={24} md={0}>
                <img src={banner_movil} style={{ width:'100%' }} />
            </Col>

          {confirmacion ? 
          (
            <Col span={24}>
            <Registrocompleto correo={correo_registrado} password={password_registrado} />
            </Col>
          )
        :
        (
          <>
          
<Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:12, offset:6}} lg={{span:18, offset:3}}>

  {/**<br />
  <p className="texto_inicio_registro">
    

  ¿Qué beneficios ofrece el <span className="texto_resaltado_evebto_inicio">uso del gas natural en el
transporte?</span> ¿qué mitos giran en torno a este combustible
fósil? ¿cuál es la perspectiva en materia de infraestructura
para su desarrollo? Más de estas preguntas estaremos
resolviendo sobre este tema alrededor de un recurso natural que aporta
<span className="texto_resaltado_evebto_inicio"> una nueva alternativa al sector del transporte.</span>

<br /><br />
<span className="texto_resaltado_evebto_inicio">¡No te lo puedes perder!</span>

  

        </p>*/}
            <Form className="login-form"
        style={{backgroundColor:'rgb(255,255,255)'}}
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
          prefix: '+52',
        }}
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            <Col span={24}>
            <p className="titulo-registrar">
              <br />
                            <strong>REGÍSTRATE</strong>
                            {!usuario && 
                            (
                              <Row style={{display:''}}>
                              <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
                              <img src ={boton_ya_cuenta} style={{width:'400px'}} onClick={()=> setMostrarLogin(true)} />
                              </Col>
                              <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                              <img src ={boton_ya_cuenta_movil} style={{width:'100%'}} onClick={()=> setMostrarLogin(true)} />
                              </Col>
                            
                              </Row>
                            )
                            }
                            <hr />
                        </p>
            </Col>
           
            <Col span={24}>
            <Form.Item className="nom-label"
        name="nombre"
        label={<span style={{color:'#606060'}}>Nombre(s)</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu nombre</span>,
          },
        ]}
        
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item className="nom-label"
        name="ap_paterno"
        label={<span style={{color:'#606060'}}>Apellido Paterno</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu apellido paterno</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item className="nom-label"
        name="ap_materno"
        label={<span style={{color:'#606060'}}>Apellido Materno</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu apellido materno</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item className="nom-label"
        name="empresa"
        label={<span style={{color:'#606060'}}>Empresa</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el nombre de tu empresa</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>

             <Col span={24}>
            <Form.Item className="nom-label"
        name="correo"
        label={<span style={{color:'#606060'}}>Correo</span>}
        rules={[
          
          {
            type: 'email',
            required: true,
            message: <span style={{color:'red'}}>Ingresa un correo válido</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>

             <Col span={24} style={{textAlign:'left'}}>
<p style={{color:'#538ae4', fontWeight:'bold', textAlign:'left', paddingBottom:'0px', marginBottom:'0px'}}><span style={{color:'red', fontWeight:'normal'}}>*</span> Número celular (a 10 dígitos).</p>
                <span style={{backgroundColor:'#538ae4', color:'white', textAlign:'left', padding:'5px', marginTop:'0px'}}>Asegúrate de ingresarlo correctamente, ya que te dará acceso el día del evento.</span>
                <br /><br />
                </Col>
                <Col span={24}>
            <Form.Item className="nom-label"
        name="telefono"
        label=""
        rules={[
          
          {
            required:true,
            pattern:/^[0-9]{7,12}$/,
            message: <span style={{color:'red'}}>Ingresa un número válido</span>
          },
        ]}
      >
        <Input addonBefore={prefixSelector} style={{width:'100%'}} />
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item className="nom-label"
        name="medio"
        label={<span style={{color:'#606060'}}>¿Por qué medio te enteraste del webinar?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Elige un medio</span>,
          },
        ]}
      >
        <Select style={{ width: '100%' }} className="nom-label" onChange={elegirMedio}>
      <Option value="Facebook">Facebook</Option>
      <Option value="Twitter">Twitter</Option>
      <Option value="Instagram">Instagram</Option>
      <Option value="Correo electrónico">Correo electrónico</Option>
      <Option value="Whatsapp">Whatsapp</Option>
      <Option value="Linkedin">Linkedin</Option>
      <Option value="Invitación de Cámara / Asociación">Invitación de Cámara / Asociación</Option>
      
    </Select>
      </Form.Item>
            {otro_medio && 
      (
        <Form.Item className="nom-label"
        name="otro_medio"
        label={<span style={{color:'#606060'}}>¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el medio por el que te enteraste</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
            </Col>
            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item className="nom-label"
        name="aceptar_terminos"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(<span style={{color:'red'}}>Acepta el aviso de privacidad</span>),
          },
        ]}
      >
        <Checkbox className="nom-label">
          Acepto el <span onClick={ () => { window.open('https://www.scania.com/mx/es/home/misc/PrivacyStatement.html','Aviso de privacidad',"top=100,left=500,width=600,height=600");}}>
                      <strong>Aviso de Privacidad</strong>
                    </span> 

          
        </Checkbox>
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item>
              <br />
              <img style={{ width:'200px', padding:'0px', cursor:'pointer' }} src={img_crear} alt="Entrar" onClick={() => { ref_form.current.submit(); }} />
                
                <Button onClick={() => setsignup(false)} type="link" style={{display:'none'}}>
                    Ingresa ahora!
                </Button>
                </Form.Item>
            </Col>
          </Row>

          <div id="recaptcha-container">
          </div>
          
        </Form>
            </Col>
            <Col xs={{span:14, offset:5}} md={{span:8, offset:8}}>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20Scania%20Digital%20Forum."><img src={wts} style={{ width:"100%" }} /></a><br /> <br />
            </Col>
            
            </>
        
        )
        }
        <Col span={4} offset={10}>
        <img src={img_camion} style={{ width:"100%" }} /><br /><br/>
        </Col>
        
            </Row>

        </Spin>

        <Modal
          title="INICIA SESIÓN"
          visible={mostrar_login}
          onOk={() => {setMostrarLogin(false)}}
          onCancel={() => {setMostrarLogin(false)}}
          footer={null}
        >
          <LoginExiste onResponse={() => {registrarLoginExiste() } } onClick={success} />
        </Modal>

        </>
        
    );
};

export default withRouter(Registro);
