import axios from 'axios';
const SERVER_URL = "https://scania.admin.thethree.com.mx";
//const SERVER_URL = "https://admin.scaniadigitalforum.scaniamexico.com";
//const SERVER_URL = "http://localhost:8000";

const fallo_token = {
    success: false,
    data:{
        status: 5
    }
}

const omitir_token = {
    success: true,
    data:{
        status: 10
    }
}

export const mail_registro_confirmacion = async (data) => {

        const ENDPOINT = `${SERVER_URL}/api/mail-scania`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
    
}

export const get_token_resgistro = async (data) => {
    const ENDPOINT = `${SERVER_URL}/webinar/buscar-usuario`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const usuario_registrado = async(telefono) => {
    const ENDPOINT = `${SERVER_URL}/api/usuario-anteriormente-registrado/${telefono}`;
    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
};


export const permitir_entrada_webinar = async (data) => {
    const ENDPOINT = `${SERVER_URL}/api/permitir-entrada`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const aceptar_codigo = async (nombre_webinar,telefono,codigo) => {
    const ENDPOINT = `${SERVER_URL}/api/verificar-telefono/${nombre_webinar}/${telefono}/${codigo}`;
        try {
            let response = await axios.get(ENDPOINT, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const responder_encuesta = async (nombre_evento, token, folio, data) => {
    const ENDPOINT = `${SERVER_URL}/api/webinar/diploma/${nombre_evento}/${token}/${folio}`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}