import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Form, Input, Button, Modal, Select, Spin, message, notification } from "antd";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import  Firebase  from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import Errores from './Errores'
import '../../assets/css/registro.css';

import { usuario_registrado } from '../../utils/ConexionServidor';

import img_crear from '../../assets/images/sitio/btn_registrate.png';
import img_entrar from '../../assets/images/sitio/btn_registrate.png';

let nuevos_datos = {};

const app = new Firebase();

let function_recaptcha;

const { Option } = Select;

let recaptcha_phone;

let medio_enterado = '';

let confirmacionCodigo;

let telefono_cod = '';
let medio_cod = '';

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const LoginExiste = ({ history, onResponse }) => {

    const { usuario, datos } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [ingresar_codigo, setIngresarCodigo] = useState(false);
    const [cargando_login, setCargandoLogin] = useState(false);
    const [otro_medio, setOtroMedio] = useState(false);
    const [verify_re, setVerifyRecaptcha] = useState(false);

    const [form] = Form.useForm();
    
    const ref_codigo = useRef(null);
    const ref_form_login = useRef(null);

    useEffect(() => {

        if (usuario) {
            //alert('usuario registrado');
        }
    }, [history, usuario]);


    const nuevoCodigo = () => {

      setVerifyRecaptcha(!verify_re);

      console.log('el telefono: ',telefono_cod);

      let nuevo_recaptcha_phone = window.recaptchaVerifier2 = new app.app.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': function(response) {
          console.log(response);
        }
      });


      /*
          app.app.auth().signInWithPhoneNumber(telefono_cod, recaptcha_phone)
            .then(function (confirmationResult) {
    // confirmationResult can resolve with the whitelisted testVerificationCode above.
    //return confirmationResult.confirm(testVerificationCode)
    function_recaptcha = confirmationResult;
    setIngresarCodigo(true);
    setCargandoLogin(false);
  }).catch(function (error) {

    recaptcha_phone = window.recaptchaVerifier.verify();
    
    console.log(error);
    // Error; SMS not sent
    // ...
    mensaje('error','Este numero no ha sido registrado, porfavor regístrate antes de iniciar sesión');
    
  });

          
*/
      
    }

    const onFinish = async values => {

        if(values.medio === 'Otro'){
            medio_enterado = values.otro_medio;
          }else{
            medio_enterado = values.medio;
          }

          console.log('el medio: ', medio_enterado);

        setCargandoLogin(true);

        const { prefix, telefono } = values;

        telefono_cod = prefix+''+telefono;
        medio_cod = medio_enterado;

        usuario_registrado(prefix+''+telefono).then(data => {
          switch(data.status){
            case 0:
              nuevos_datos = data.data;
              nuevos_datos.medio = medio_enterado;
              console.log(nuevos_datos);
              onResponse(nuevos_datos);
              
              /*app.app.auth().signInWithPhoneNumber(prefix+telefono, recaptcha_phone)
              .then(function (confirmationResult) {
                // confirmationResult can resolve with the whitelisted testVerificationCode above.
                //return confirmationResult.confirm(testVerificationCode)
                function_recaptcha = confirmationResult;
                setIngresarCodigo(true);
                setCargandoLogin(false);
              }).catch(function (error) {
                // Error; SMS not sent
                // ...
                mensaje('error','Este número no ha sido registrado, por favor regístrate antes de iniciar sesión');
                
                setIngresarCodigo(false);
                setCargandoLogin(false);
              });*/
              break;
              default:
                mensaje('error','Este teléfono no se encuentra registrado, por favor regístrate antes de iniciar sesión');
            
            setCargandoLogin(false);
              break;
          }
          setCargandoLogin(false);
        });

    };

    const elegirMedio = value => {
        console.log(value);
  
        if(value === 'Otro'){
          setOtroMedio(true);
        }else{
          setOtroMedio(false);
        }
  
      }


    useEffect(() => {
        recaptcha_phone = window.recaptchaVerifier = new app.app.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //ref_form_login.current.submit();
            }
          });
    },[]);

    const colocarCodigo = () => {
        setCargandoLogin(true);
        console.log(ref_codigo.current.state.value);

        function_recaptcha.confirm(ref_codigo.current.state.value).then(function (result) {
            // User signed in successfully.
            var user = result.user;
            
            nuevos_datos.medio = medio_enterado;
            console.log(nuevos_datos);
            onResponse(nuevos_datos);
            setCargandoLogin(false);
            // ...
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
            mensaje('error','Código incorrecto');
            setCargandoLogin(false);
          });
          

      }

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            className="select_telefono"
          >
            <Option value="+500" >+500 FK</Option>
            <Option value="+501" >+501 BZ</Option>
            <Option value="+502" >+502 GT</Option>
            <Option value="+503" >+503 SV</Option>
            <Option value="+504" >+504 HN</Option>
            <Option value="+505" >+505 NI</Option>
            <Option value="+506" >+506 CR</Option>
            <Option value="+507" >+507 PA</Option>
            <Option value="+508" >+508 PM</Option>
            <Option value="+509" >+509 HT</Option>
            <Option value="+51" >+51 PE</Option>
            <Option value="+52" >+52 MX</Option>
            <Option value="+53" >+53 CU</Option>
            <Option value="+54" >+54 ARG</Option>
            <Option value="+55" >+55 BR</Option>
            <Option value="+56" >+56 CL</Option>
            <Option value="+57" >+57 CO</Option>
            <Option value="+58" >+58 VE</Option>
            <Option value="+590" >+590 GP</Option>
            <Option value="+591" >+591 BO</Option>
            <Option value="+593" >+593 EC</Option>
            <Option value="+594" >+594 GF</Option>
            <Option value="+595" >+595 PY</Option>
            <Option value="+596" >+596 MQ</Option>
            <Option value="+597" >+597 SR</Option>
            <Option value="+598" >+598 UY</Option>
            <Option value="+809" >+809 DO</Option>
          </Select>
        </Form.Item>
      );

    return (
        <Row className="contenedor_registro">
            <Col span={24}>
            <Spin spinning={cargando_login}>
            {!ingresar_codigo ?
            (
                    <Form className="login-form" 
                    onFinish={onFinish} 
                    ref={ref_form_login}
                    layout="vertical"
                    initialValues={{
                        prefix: '+52',
                      }} >
                        
                        
                        <Form.Item
        name="telefono"
        label={<span className="color_naranja" style={{color:'#fe633f'}}>Número celular</span>}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa un número válido</span>,
          },
        ]}
      >
        <Input
          addonBefore={prefixSelector}
          style={{
            width: '100%',
            backgroundColor:'white'
          }}
        />
      </Form.Item>
      
            <Form.Item className="nom-label"
        name="medio"
        label={<span style={{color:'#606060'}}>¿Por qué medio te enteraste?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Elige un medio</span>,
          },
        ]}
      >
        <Select style={{ width: '100%' }} className="nom-label" onChange={elegirMedio}>
      <Option value="Facebook">Facebook</Option>
      <Option value="Twitter">Twitter</Option>
      <Option value="Instagram">Instagram</Option>
      <Option value="Correo electrónico">Correo electrónico</Option>
      <Option value="Whatsapp">Whatsapp</Option>
      <Option value="Linkedin">Linkedin</Option>
      <Option value="Invitación de Cámara / Asociación">Invitación de Cámara / Asociación</Option>
      <Option value="Otro">Otro</Option>
    </Select>
      </Form.Item>
            {otro_medio && 
      (
        <Form.Item className="nom-label"
        name="otro_medio"
        label={<span style={{color:'#606060'}}>¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa el medio por el que te enteraste</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
                        {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                        <Form.Item>
                            <Row>
                                
                                <Col span={24} style={{textAlign:'center'}}>
                                <br />
                                    <Button id="sign-in-button" className="boton_recibir_codigo" htmlType="submit">Recibir código</Button>
                                    
                                </Col>
                            </Row>
                            
                        </Form.Item>

                    </Form>
            
            )
            :
            (
                <Row>
                    <Col span={24}>
                <p style={{textAlign:'center', color:'#fe633f'}}><br />Ingresa el código que llego a tu teléfono</p>
            </Col>
            <Col span={18}>
                <Input style={{width:'100%'}} ref={ref_codigo} />
                
            </Col>
            <Col span={6}>
                <Button className="boton_recibir_codigo" style={{width:'100%'}} onClick={colocarCodigo}>Verificar</Button>
            </Col>
            <Col span={24}>
            <br />
                <span>El código te llegará vía SMS al teléfono registrado. Esto podría tardar unos minutos.


                </span>
                <p style={{textAlign:'center', display:'none'}}>
                <span style={{textAlign:'center', color:'#fe633f', cursor:'pointer'}} onClick={nuevoCodigo}>Recibir nuevo código</span>
                </p>
            </Col>
                </Row>
            )
            }
</Spin>
</Col>
        
        </Row>

    );
}

export default LoginExiste;
