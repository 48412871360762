//import * as firebase from 'firebase';
import 'firebase/auth';
import firebase from 'firebase';
import "firebase/firestore";
import "firebase/storage";

// Your web app's Firebase configuration

var firebaseConfig = {
    apiKey: "AIzaSyDmmDLfWCtMNXO_zPiudasbS9aGYCkARdU",
    authDomain: "scania-webinar.firebaseapp.com",
    databaseURL: "https://scania-webinar.firebaseio.com",
    projectId: "scania-webinar",
    storageBucket: "scania-webinar.appspot.com",
    messagingSenderId: "379782991713",
    appId: "1:379782991713:web:a09b0759f63d2100f2bf20",
    measurementId: "G-CCDDZGWNB9"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();


class Firebase {

    app;

    constructor(){
        this.app = firebase;
        this.conexion = firebase.database();
    }

    obtener_webinars_usuario = (url, campo, valor, funct) => {


        this.conexion.ref(url).once('value', (objetos) => {

            if (this.collectionToArr(objetos).length > 0) {

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if(objeto_buscado.length > 0){
                    return funct(objeto_buscado[0]);
                }
            }
            return null;
        })
    }
    
    obtener_objeto = (url, campo, valor, funct) => {
        
        this.conexion.ref(url).once('value', (objetos) => {

            if (this.collectionToArr(objetos).length > 0) {

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if(objeto_buscado.length === 1){
                    return funct(objeto_buscado[0]);
                }else{
                    const datos_objeto = {};
                    datos_objeto[campo] = valor;
                    this.escribir(url, undefined, datos_objeto, funct);
                }
            } else {
                const datos_objeto = {};
                datos_objeto[campo] = valor;
                this.escribir(url, undefined, datos_objeto, funct);
            }
        });
    }

    verificar_objeto = (url, campo, valor, funct) => {
        
        this.conexion.ref(url).once('value', (objetos) => {

            if (this.collectionToArr(objetos).length > 0) {

                let objeto_buscado = this.collectionToArr(objetos).filter(objeto => {
                    if (objeto[campo] === valor) {
                        return true;
                    } else {
                        return false;
                    }
                });


                if(objeto_buscado.length > 0){
                    funct(true);
                }else{
                    funct(false);
                }
            } else {
                funct(false);
            }
        });
    }

    escribir = (url, id, objeto, funct) => { //sendquestion || updateQuestion
        let key = '';
        if (id === undefined) {
            key = this.conexion.ref(url).push().key;
        } else {
            key = id;
        }

        console.log('el objeto: ');
        console.log(objeto);

        this.conexion.ref(url).child(key).set(objeto, () => {
            objeto['key'] = key;
            return funct(objeto);
        });
    }

    obtener_objetos = (url, escuchar ,funct) => { //questions

        if(escuchar){
            this.conexion.ref(url).orderByChild("num_pregunta").on('value', (objetos) => {
                return funct(this.collectionToArr(objetos));
            })
        }else{
            this.conexion.ref(url).orderByChild("num_pregunta").once('value', (objetos) => {
                return funct(this.collectionToArr(objetos));
            })
        }
    }

    obtener_parametro = (url, escuchar ,funct) => {

        if(escuchar){
            this.conexion.ref(url).on('value', (parametro) => {
                return funct(parametro);
            })
        }else{
            this.conexion.ref(url).once('value', (parametro) => {
                return funct(parametro);
            })
        }
    }

    borrar_objeto = (url, funct) => {
        this.conexion.ref(url).remove();
    }

    collectionToArr = (collection) => {
        var rows = [];
        if (collection && collection.val()) {

            collection.forEach((obj) => {
                let item = obj.val();
                item.key = obj.key;
                rows.push(item);
            });
        }
        return rows;
    }

    collectionToArr = (collection) => {
        var rows = [];
        if (collection && collection.val()) {

            collection.forEach((obj) => {
                let item = obj.val();
                item.key = obj.key;
                rows.push(item);
            });
        }
        return rows;
    }


}

export default Firebase;