import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import trans from '../../assets/images/registro/btn_transmision.png';

const RegistroCompleto = (props) => {

    const {correo, password} = props;

    return(
        <Row justify="center" align="middle">
            <Col span={22} offset={1}><br /><br />
                <p className="titulo-completo">REGISTRO EXITOSO</p>
                <hr />
                <p style={{ textAlign:'center' }}>
                <span className="texto-breve">En breve recibirás un correo de confirmación a la siguiente dirección: </span><br /><br />

            <span className="correo-azul"> <strong>{correo}</strong> </span><br /> <br />
            <span className="leyenda">Si no encuentras el mail de confirmación en tu bandeja de entrada, es posible que esté en la <br />carpeta de SPAM / NO DESEADOS.</span> <br/> <br/>
            <span className="fondo">INSTRUCCIONES DE ACCESO:</span><br />
            </p>
            <Row >
                <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p className="numero_instruccion" >
                <span className="datos-azul"><strong >1. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}} >
                <p className="texto_instruccion" >
                Ingresa a 
                <Link to="/" className="datos-azul"><strong> scaniadigitalforum.scaniamexico.com</strong></Link></p>
                </Col>
                
            </Row>
            <Row >
            <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p className="numero_instruccion">
                <span className="datos-azul"><strong>2. </strong></span></p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p className="texto_instruccion" >
                Da click en la pestaña <span className="datos-azul"><strong>“EN VIVO”.</strong></span></p>
                </Col>
            </Row>
            <Row >
            <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p className="numero_instruccion">
                <span className="datos-azul"><strong>3. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p className="texto_instruccion" >
                El sistema te pedirá tu número celular <span className="datos-azul"><strong>(el número debe ser el mismo que registraste).</strong></span></p>
                </Col>
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p className="numero_instruccion">
                <span className="datos-azul"><strong>4. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p className="texto_instruccion" >
                El sistema te enviará un <span className="datos-azul"><strong>SMS con un código, ingrésalo.</strong></span></p>
                </Col>
            </Row>
            <Row >
                <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p className="numero_instruccion">
                <span className="datos-azul"><strong>5. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p className="texto_instruccion" >
                Disfruta del evento.</p>
                </Col>
            </Row>
           
            <Link to="/en-vivo" className="datos-azul"><img src={trans} className="transmision" /></Link>

            <br /><br />
            <p className="nota">La transmisión se habilitará 30 minutos antes del evento.</p><br /> <br />
          
            </Col>
            
            </Row>
    );
}

export default RegistroCompleto;