import React, { useContext, useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, Button, notification } from 'antd';
import '../../assets/css/evento.css';
import { MenuOutlined, WindowsFilled } from '@ant-design/icons';


import ima from '../../../src/assets/images/registro/logo-scaniaforum.png';
import logo_scania from '../../../src/assets/images/registro/logoScania_typo.png';
import ima2 from '../../../src/assets/images/registro/logo-escudoscania.png';
import {Auth} from '../../utils/AuthContext';
import Login from '../registro/Login';
import LoginSinRegistro from '../registro/LoginSinRegistro';

import img_mesas from '../../assets/images/sitio/home/btn-mesas_letra.png';
import img_webinar from '../../assets/images/sitio/home/btn-webinars_letra.png';
import img_conf from '../../assets/images/sitio/home/icono_conferencias_y_foros.png';
import img_icono_lanzamiento from '../../assets/images/sitio/home/btn-lanzamientos_.png';
import img_lanza from '../../assets/images/sitio/home/btn-lanzamiento_letra.png';
import img_talks from '../../assets/images/sitio/home/iconos-talks_y_workshops.png';



const secciones = [
  {nombre:'INICIO', url:'/inicio',ver:false},
  {nombre:'REGISTRO', url:'/registro'},
  //{nombre:'PONENTES', url:'/ponentes'},
  {nombre:'EN VIVO', url:'/en-vivo'},
];

const registros = [
  //{nombre:'¿QUIÉNES SON LOS GRANDES GANADORES DEL TRANSPORTE EN MÉXICO?', url:'/registro/grandes-ganadores-del-transporte-en-mexico'},
  //{nombre:'PONENTES', url:'/ponentes'},
  //{nombre:'EN VIVO', url:'/en-vivo'},
];


const secciones_movil = [
  {nombre:'INICIO', url:'/inicio',ver:false},
  {nombre:'REGISTRO', url:'/registro'},
  //{nombre:'PONENTES', url:'/ponentes'},
  {nombre:'EN VIVO', url:'/en-vivo'},
];

const success = () => {
  mensaje('success','Bienvenido','');
};

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}

const Header = ({mostrar_boton_login, onResponseMostrarLogin}) => {

  const { usuario } = useContext(Auth);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_menu, setMostrarMenu] = useState(false);
  const [mostrar_registros, setMostrarRegistros] = useState(false);
  const [redireccionar_en_vivo, setRedireccionarEnVivo] = useState(false);

  useEffect(()=>{
    if(redireccionar_en_vivo){
      console.log('redireccionar');
      window.location.href="/en-vivo";
    }
  },[redireccionar_en_vivo]);
  let location = useLocation();

  useEffect(() => {
    if (!usuario) {
      if(location.pathname === '/en-vivo'){
        onResponseMostrarLogin(true);
      }
    }
  },[location]);

  

    let visualizar_secciones_sub = registros.map((seccion)=>{
      return(
        <>
        {location.pathname === '/' || location.pathname === '/inicio' ?
        <>
        {seccion.ver ?
        <p className="contenedor_submenu">
        <Link to={seccion.url} className="link_submenu">
        {seccion.nombre}
      </Link>
      </p>
      :
      <></>
        }
        </>
      :
        <p className="contenedor_submenu">
            <Link to={seccion.url} className="link_submenu">
            {seccion.nombre}
          </Link>
          </p>}
        </>
       
      )
    });

    let visualizar_secciones_sub_movil = registros.map((seccion)=>{
      return(
        <Col span={24} className="">
          <Link to={seccion.url} className="link_sub_movil">
          {seccion.nombre}
        </Link>
        </Col>
       
      )
    });
    let visualizar_secciones = secciones.map((seccion)=>{
      if(false){ //Para varios registros seccion.nombre === 'REGISTRO'
      return(
        <div className="contenedor_seccion_menu" onMouseEnter={() => setMostrarRegistros(true)} onMouseLeave={() => setMostrarRegistros(false)}>
        <Link className="titles" to={seccion.url} >
          <span className={location.pathname+''+location.hash === seccion.url ? "seccion_sel" : "seccion"} >
            {seccion.nombre}
          </span>
        </Link>
        {mostrar_registros && (
          <div className="contenedor_submenu_row">
            {visualizar_secciones_sub}
          </div>
        )
        }
        </div>
      );
    }else{
      return(
        <>
        {location.pathname === '/-' || location.pathname === '/inicio-' ?
        <>
        {seccion.ver ?
        <div className="contenedor_seccion_menu">
        <Link className="titles" to={seccion.url}>
          <span className={location.pathname+''+location.hash === seccion.url ? "seccion_sel" : "seccion"} >
            {seccion.nombre}
          </span>
        </Link>
        </div>
        :
        <></>}</>
        :
        <div className="contenedor_seccion_menu">
        <Link className="titles" to={seccion.url}>
          <span className={location.pathname+''+location.hash === seccion.url ? "seccion_sel" : "seccion"} >
            {seccion.nombre}
          </span>
        </Link>
        </div>
    }</>
      );
    }
    });

    let visualizar_secciones_movil = secciones_movil.map((seccion)=>{
      if(false){ //Para varios registros seccion.nombre === 'REGISTRO'
      return(
       
        <div className="contenedor_seccion_menu" onClick={() => setMostrarRegistros(!mostrar_registros)}>
        <p className="titles">
          <span className={location.pathname+''+location.hash === seccion.url ? "seccion_sel" : "seccion"} >
            {seccion.nombre}
          </span>
        </p>
        {mostrar_registros && (
          <Row className="">
            {visualizar_secciones_sub_movil}
          </Row>
        )
        }
        </div>
      );
      }else{
        return(
       
          <Link className="titles" to={seccion.url} onClick={() => {setMostrarMenu(false);}}>
            <span className={location.pathname+''+location.hash === seccion.url ? "seccion_sel" : "seccion"} >
              {seccion.nombre}
            </span>
          </Link>
        );
      }
    });

  return(
    <>

    
    <Row align="middle" style={{paddingTop:'10px', paddingBottom:'10px'}}>

      <Col xs={{span:4, offset:0}} md={{span:3, offset:1}} style={{ padding: '0px' }}>
      
      <img alt="logo" style={{width:'100%'}} src={logo_scania}/>
      

      </Col>

      <Col xs={{span:0, offset:0}} md={{span:14, offset:1}} style={{textAlign:'left'}}>
        
        
        {visualizar_secciones}

        <span className="cerrar_chico" style={{display:'non'}}>
        {usuario ?
        (
          <Link className="titles" to="/cerrar-sesion">
            <span className="seccion">
              CERRAR
            </span>
          </Link>
        ) 
        :
        (
          <Link className="titles" onClick={() => {onResponseMostrarLogin(true)}} to="">
            <span className="seccion" >
              ENTRAR
            </span>
          </Link>
        )
      }
        </span>
        
      </Col>



      <Col xs={{span:0, offset:0}} md={{span:4, offset:0}} style={{ padding: '0px' }}>
        <a href="https://scaniamexico.com" target="_blank"><img alt="logo" className="img_logo_scania_" src={ima2}/></a>
      </Col>

      <Col xs={{span:3, offset:17}} md={{span:0, offset:0}} >
        <MenuOutlined onClick={() => { setMostrarMenu(!mostrar_menu);}} />
      </Col>
      {mostrar_menu && 
      <Col xs={{span:24, offset:0}} md={{span:0, offset:0}} style={{backgroundColor:'white'}}>
        <br />
        <Row style={{ zIndex:99, textAlign:'center'}}>
          <Col span={24}>
          { visualizar_secciones_movil }
        <span className="cerrar_chico" onClick={() => {setMostrarMenu(false);}} style={{display:'non'}}>
        {usuario ?
        (
          <Link className="titles" to="/cerrar-sesion">
            <span className="seccion">
              CERRAR
            </span>
          </Link>
        ) 
        :
        (
          <Link className="titles" onClick={() => {onResponseMostrarLogin(true)}} to="">
            <span className="seccion" >
              ENTRAR
            </span>
          </Link>
        )
      }
        </span>
          </Col>
        </Row>
      </Col>
}
    </Row>

    <Row style={{backgroundColor:'white'}}>
      <Col xs={{span:5, offset:2}} md={{span:4, offset:4}}>
        <Link to="/conferencias">
        <img src={img_conf} style={{width:'50%'}} />
        </Link>
      </Col>
      <Col xs={{span:5, offset:0}} md={{span:4, offset:0}}>
        <Link to="/webinars">
        <img src={img_webinar} style={{width:'36%'}} />
        </Link>
      </Col>
      <Col xs={{span:5, offset:0}} md={{span:4, offset:0}}>
      <Link to="/lanzamientos">
        <img src={img_lanza} style={{width:'52%'}} />
        </Link>
      </Col>
      <Col xs={{span:5, offset:0}} md={{span:4, offset:0}}>
      <Link to="/talks-workshops">
        <img src={img_talks} style={{width:'52%'}} />
        </Link>
      </Col>
    </Row>

    <Modal
          title="INICIA SESIÓN"
          visible={false}//mostrar_boton_login
          onOk={() => {onResponseMostrarLogin(false)}}
          onCancel={() => {onResponseMostrarLogin(false)}}
          footer={null}
        >
          <Login onResponse={() => {onResponseMostrarLogin(false); } } onResponseLogin={()=> {onResponseMostrarLogin(false); setRedireccionarEnVivo(true); }} onClick={success} />
        </Modal>
    </>
    );
}

export default Header;