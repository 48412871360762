import React from 'react';
import { Row, Col } from 'antd';

import Header from '../master/Header';
import Footer from '../master/Footer';

import revivir from '../../assets/images/sitio/home/btn-revivelo_webinars.png';
import presen from '../../assets/images/sitio/home/btn-presentaciones.png';
import pasado from '../../assets/images/sitio/home/talks_etiqueta_pasado.jpg';
import conf_pasada from '../../assets/images/sitio/home/etiqueta_conferenciapasada.png';


import '../../assets/css/conferencia.css';

const conferencias = [
    { anio: '2023', fecha: '27 JULIO', live: '', nombre: 'Situación Financiera en México 2023', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/SZ-fZHVhc5s', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2022', fecha: '7 SEPTIEMBRE', live: '', nombre: 'Importancia del respaldo de los grupos electrógenos', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/jwMtWTlECOQ', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2022', fecha: '19 AGOSTO', live: '', nombre: 'Déficit y Falta de Operadores', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/bRdqKrsN38M', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2022', fecha: '29 JUNIO', live: '', nombre: 'Seguridad Carretera, El impacto de la tecnología en la movilidad', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/VBfbVm1AEUI', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },

    { anio: '2022', fecha: '22 ABRIL', live: '', nombre: 'Dia nacional de la responsabilidad social', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/crS2XPG38mo', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2022', fecha: '29 MARZO', live: '', nombre: 'Transporte y perspectiva de género', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/dHjjQyhUYXk', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2022', fecha: '22 FEBRERO', live: '', nombre: 'Adopción de los vehículos eléctricos en América', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/HoLfwZB5kdQ', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2022', fecha: '25 ENERO', live: '', nombre: 'El rol del transporte en temporada invernal. Impactos ambientales del transporte', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/8rj-rdzQhOI', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '25 NOVIEMBRE', live: '', nombre: 'Innovación empresarial y cómo implementarla', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/aLO0WMKMWlA', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '29 SEPTIEMBRE', live: '', nombre: 'Movilidad Urbana eléctrica', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/6tTr0znSEp4', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '7 SEPTIEMBRE', live: '', nombre: 'E-commerce, industria del transporte y postventa', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/rZHnM4F8JmA', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '24 AGOSTO', live: '', nombre: 'Los nuevos escenarios para el transporte y la cadena de suministro', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/TxkgjC749aI', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '29 JULIO', live: '', nombre: 'Impactos en el transporte de pasajeros y reactivación del sector', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/P2jEg-gcWDE', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '9 JULIO', live: '', nombre: 'Retos y transformación del transporte de turismo Poscovid', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/jzL8XbcPMn4', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    { anio: '2021', fecha: '25 JUNIO', live: '', nombre: '“Movilidad, energía y cambio climático”', descripcion: '', dia: '1', img: '', url: 'https://youtu.be/APDzkBna1xw', link: revivir, presencaiones: '', pas: pasado, descargar: '', clas: '' },
    
];


const Conferencias = () => {


    let visualizar_conferencias = conferencias.map((conferencia) => {
        return (
            <div className="info-horario_conf">

                <Row justify="center" align="middle">
                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 3, offset: 0 }}>
                        <p style={{ textAlign: 'center', marginBottom: '0em' }}>
                            <img alt="" style={{ width: '50%' }} src={conferencia.live} /><br />
                            <span className="info-fecha_conf">{conferencia.fecha}</span></p>
                        <p style={{ textAlign: 'center' }}>
                            <span className="info-anio_conf">{conferencia.anio}</span>
                        </p>
                    </Col>
                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 11, offset: 1 }} className="sec-titulo_conf">
                        <p className="info-completa_conf">
                            <span className="info-nombre_conf">{conferencia.nombre}</span><br />
                            <img className="web-pasado_conf" src={conferencia.pas} />
                            <br />
                        </p>
                    </Col>
                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 4, offset: 0 }} >
                        <a href={conferencia.url} target="_blank">
                            <img src={"https://img.youtube.com/vi/" + conferencia.url.substring(17, 28) + "/mqdefault.jpg"} className="info-video_conf" style={{ width: '100%' }} />
                        </a>
                    </Col>
                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 4, offset: 0 }} className={conferencia.clas}>
                        <a href={conferencia.url} target="_blank">
                            <img style={{ width: '70%' }} src={conferencia.link} />
                        </a><br />

                        <a href={conferencia.descargar} download>
                            <img alt="" style={{ width: '70%' }} src={conferencia.presencaiones} />
                        </a>
                    </Col>
                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 22, offset: 2 }} >
                        <hr style={{ color: '#000000' }} />
                    </Col>
                </Row>
            </div>
        );
    });


    return (
        <>
            <Row className="contenedor_conferencias">
                <Col span={24}>
                    <br />
                    <p className="palabra_inicio_seccion">SCANIA TALKS & WORKSHOPS</p>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 14, offset: 4 }} lg={{ span: 16, offset: 4 }} style={{ textAlign: 'center' }}>
                    <div className="contenedor_video_conferencia">
                        <iframe src="https://www.youtube.com/embed/SZ-fZHVhc5s?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Col>
                <Col span={24}>
                    <br /><br /><br />
                </Col>
                <Col span={20} offset={2} className="contenedor_programas">
                    {visualizar_conferencias}
                </Col>
                <Col span={24}>
                    <br /><br /><br />
                </Col>
            </Row>

        </>
    );
}

export default Conferencias;