import React from 'react';
import { Row, Col } from 'antd';

import Header from '../master/Header';
import Footer from '../master/Footer';

import revivir from '../../assets/images/sitio/home/btn-revivelo_webinars.png';
import presen from '../../assets/images/sitio/home/btn-presentaciones.png';
import conf_pasada from '../../assets/images/sitio/home/etiqueta_conferenciapasada.png';
import foro_pasada from '../../assets/images/sitio/home/foro_etiqueta_pasado.jpg';

import img4 from '../../assets/images/sitio/home/minivistaw-gestioria.jpg';
import img5 from '../../assets/images/sitio/home/minivistaw-invierteencapacitacion.jpg';
import img6 from '../../assets/images/sitio/home/minivistaw-ventajasentuoperacion.jpg';
import img51 from '../../assets/images/sitio/home/minivistaw-flotautobuses.jpg';
import img7 from '../../assets/images/sitio/home/minivistaw-mejoraelrendimiento.jpg';
import img8 from '../../assets/images/sitio/home/minivistaw-comocuidartusautobuses.jpg';
import img9 from '../../assets/images/sitio/home/minivistaw-FOCUS-RESET.jpg';
import img2 from '../../assets/images/sitio/home/minivistaw-recomendaciones.jpg';
import agosto13 from '../../assets/presentaciones/SCANIA_GESTION_EN_EL_TALLER.pdf';

import '../../assets/css/conferencia.css';
import CarouselPrincipal from '../evento/CarouselPrincipal';

const conferencias = [
  {anio:'2022', fecha:'14 MARZO', live:'', nombre:'Foro inclusión de género en la industria del transporte', descripcion:[<span>En el marco de las actividades entorno al #8M 2022 cinco líderes de la industria se reunieron  para compartir retos, soluciones y las mejores prácticas que se han implementado en la industria del transporte en favor de la inclusión de género.</span>], dia:'1', img:'', url:'https://youtu.be/JryTZLlmxag', link:revivir, presencaiones:'', pas:foro_pasada, descargar:'', clas:'', desc:[]  },
  {anio:'2021', fecha:'5 MARZO', live:'', nombre:'Foro liderazgo femenino', descripcion:[<span>En nuestro primer Foro Digital sobre Liderazgo Femenino contamos con la presencia de 6 mujeres líderes que nos hablaron sobre las acciones que llevan en torno a la equidad de género en la industria.</span>], dia:'1', img:'', url:'https://youtu.be/oEGMLEC4fXQ', link:revivir, presencaiones:'', pas:foro_pasada, descargar:'', clas:''  },
    {anio:'2020', fecha:'27 AGOSTO', live:'', nombre:'Focus & Re:Set.', descripcion:[<span>Nuestro nuevo director general nos explica que el principal objetivo de Focus & Reset es colocar al cliente en el centro de la estrategia siendo la clave de Scania para reactivar al mercado.</span>], dia:'1', img:img9, url:'https://youtu.be/NpwllW18vdo', link:revivir, presencaiones:'',pas:conf_pasada,descargar:'../../../public/presentaciones', clas:''},
  ];


const Conferencias = () => {


    let visualizar_conferencias = conferencias.map((conferencia)=>{
        return(
          <div className="info-horario_conf">
            
            <Row justify="" align="middle">
              <Col xs={{span:20, offset:2}} md={{span:3, offset:0}}>
                <p style={{ textAlign:'center', marginBottom: '0em' }}>
                  <img alt="" style={{ width:'50%' }} src={conferencia.live}/><br/>
                  <span className="info-fecha_conf">{conferencia.fecha}</span></p>
                  <p style={{ textAlign:'center' }}>
                  <span className="info-anio_conf">{conferencia.anio}</span>
                  </p>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:1}} className="sec-titulo_conf">
                <p className="info-completa_conf">
                <span className="info-nombre_conf">{conferencia.nombre}</span></p>
                <p className='info-desc_conf'>{conferencia.descripcion}<br/><br/>
                <img className="web-pasado_conf" src={conferencia.pas} />
                <br />
                </p>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:4, offset:0}} >
                <a href={conferencia.url} target="_blank">
                <img src={"https://img.youtube.com/vi/"+conferencia.url.substring(17,28)+"/mqdefault.jpg"} className="info-video_conf" style={{ width:'100%' }}/>
                </a>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:4, offset:0}} className={conferencia.clas}>
                <a href={conferencia.url} target="_blank">
                  <img  style={{ width:'70%' }} src={conferencia.link}/>
                </a><br />
        
                <a href={conferencia.descargar} download>
                  <img alt="" style={{ width:'70%' }} src={conferencia.presencaiones}/>
                </a>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:22, offset:2}} >
                <hr style={{ color:'#000000' }} />
              </Col>
            </Row>
          </div>
        );
      });


    return(
      <>
      <Row className="contenedor_conferencias">
        <Col span={24} style={{display:'noe'}}>
          <br />
          <p className="palabra_inicio_seccion">CONFERENCIAS Y FOROS</p>
        </Col>
        <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:14, offset:4}} lg={{span:16, offset:4}} style={{textAlign:'center', display:'non'}}>
          <div className="contenedor_video_conferencia">
          <iframe src="https://www.youtube.com/embed/JryTZLlmxag" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Col>

        <Col span={24} style={{backgroundColor:'white', display:'none'}} >
        <CarouselPrincipal />
      </Col>
        <Col span={24}>
          <br /><br /><br />
        </Col>
        <Col span={20} offset={2} className="contenedor_programas">
        {visualizar_conferencias}
        </Col>
        <Col span={24}>
          <br /><br /><br />
        </Col>
      </Row>
        </>
    );
}

export default Conferencias;