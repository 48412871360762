import React, {useContext, useEffect, useState} from 'react';
import { Form, Input, Button, Radio, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

const app = new Firebase();
const nombre_webinar = "volver_futuro";

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

const Encuesta = (props) => {

    const { usuario, datos } = useContext(Auth);
    const [etapa, setEtapa] = useState(0);


    const onFinish = (values) => {
        values.telefono = datos.telefono;
        values.correo = datos.correo;
        values.nombre = datos.nombre + ' ' + datos.apellidos;

        app.escribir(nombre_webinar+'/encuesta-usuarios-post',undefined, values ,(data) => {
            setEtapa(1);
          });

      };

      useEffect(()=>{
          app.verificar_objeto(nombre_webinar+'/encuesta-usuarios-post','telefono',datos.telefono,(data)=>{
            if(data){
                setEtapa(1);
            }else{
                setEtapa(0);
            }
          });
      },[usuario]);

      useEffect(()=>{
          if(!usuario){
            setEtapa(2);
          }
        });

      return (
          <Row>
              {etapa === 0 ?
                <Col xs={{span:20, offset:2}} md={{span:14, offset:5}}>
        <br />
              
          <Row align="middle" style={{border:'1px solid rgba(230,230,230,0)'}}>
              <Col span={24}>
                  <br />
                <p style={{textAlign:'center', fontWeight:'bold', fontSize:'30px'}}>
                    ENCUESTA DE SATISFACCIÓN
                </p>
              </Col>
              <Col >
              <Form
        layout="vertical"
          onFinish={onFinish}
        >
            <Row>
                <Col span={24} style={{textAlign:'center'}}>
                <br />
                    <p>1. ¿Cómo consideras la calidad de la información recibida?</p>
                <Form.Item
            label={null}
            name="pregunta1"
            rules={[
              {
                required: true,
                message: <span style={{color:'red'}}>Selecciona una respuesta</span>,
              },
            ]}
          >
            <Radio.Group>
                <Radio style={radioStyle} value={"Excelente"}>
                    Excelente
                </Radio>
                <Radio style={radioStyle} value={"Buena"}>
                Buena
                </Radio>
                <Radio style={radioStyle} value={"Regular"}>
                Regular
                </Radio>
            </Radio.Group>

          </Form.Item>
                </Col>
                <Col span={24}>
                <br />
                <p>2. Tu posición dentro de la empresa para la que laboras es:</p>
                <Form.Item
            label={null}
            name="pregunta2"
            rules={[
              {
                required: true,
                message: <span style={{color:'red'}}>Selecciona una respuesta</span>,
              },
            ]}
          >
            <Radio.Group>
                <Radio style={radioStyle} value={"Dirección"}>
                Dirección
                </Radio>
                <Radio style={radioStyle} value={"Gerencia"}>
                Gerencia
                </Radio>
                <Radio style={radioStyle} value={"Operaciones"}>
                Operaciones
                </Radio>
                <Radio style={radioStyle} value={"Servicios"}>
                Servicios
                </Radio>
            </Radio.Group>

          </Form.Item>
                </Col>
                <Col span={24}>
                <br />
                <p>3. ¿Te gustaría ser contactado por nuestro equipo para conocer más acerca de…?</p>
                <Form.Item
            label={null}
            name="pregunta3"
            rules={[
              {
                required: true,
                message: <span style={{color:'red'}}>Selecciona una respuesta</span>,
              },
            ]}
          >
            <Radio.Group>
            <Radio style={radioStyle} value={"Pólizas de mantenimiento / Nuevas soluciones"}>
            Pólizas de mantenimiento / Nuevas soluciones
                </Radio>
                <Radio style={radioStyle} value={"Servicios Conectados"}>
                Servicios Conectados
                </Radio>
                <Radio style={radioStyle} value={"Capacitación a conductores"}>
                Capacitación a conductores
                </Radio>
                <Radio style={radioStyle} value={"Producto / Autobuses urbanos"}>
                Producto / Autobuses urbanos
                </Radio>
                <Radio style={radioStyle} value={"No quisiera ser contactado"}>
                No quisiera ser contactado
                </Radio>
            </Radio.Group>

          </Form.Item>
                </Col>
                <Col span={24}>
                    <br />
                <p>4. ¿Qué temas te gustaría que abordáramos en los siguientes webinar de autobuses?</p>
                <Form.Item
            label={null}
            name="pregunta4"
            rules={[
              {
                required: true,
                message: <span style={{color:'red'}}>Contesta esta pregunta</span>,
              },
            ]}
          >
           <TextArea />

          </Form.Item>
                </Col>
            </Row>

          <Form.Item >
            <Button type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
        <br /><br />
              </Col>
          </Row>
          </Col>
              :
              etapa === 1 ?
              <Col span={24}>
                <br /><br /><br />
                <br /><br /><br />
                
                <p style={{textAlign:'center'}}>Gracias por participar...</p>
                <br /><br /><br />
                <br /><br /><br />
                <br /><br /><br />
            </Col>
              :
              <Col span={24}>
                <br /><br /><br />
                <br /><br /><br />
                
                <p style={{textAlign:'center'}}>Por favor loguate antes de contestar esta encuesta...</p>
                <br /><br /><br />
                <br /><br /><br />
                <br /><br /><br />
            </Col>

              }
              
          </Row>
        );
}

export default Encuesta;